import React from "react"

const TikTokIcon = ({ height = 32, width = 32, color = "#55778A" }) => (
  <svg width={width} height={height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.8575 6.40039V6.41653C19.8585 6.70882 19.9441 10.9343 24.3784 11.1978C24.3784 15.1289 24.3804 11.1978 24.3804 14.4627C24.0473 14.4825 21.4616 14.2956 19.8526 12.8605L19.8476 19.2163C19.8874 22.0943 18.2853 24.9166 15.2859 25.4854C14.4456 25.6446 13.689 25.6625 12.4188 25.385C5.10158 23.1961 7.53303 12.3434 14.8802 13.5049C14.8802 17.0086 14.8822 13.5039 14.8822 17.0086C11.847 16.562 10.8317 19.087 11.6382 20.895C12.3722 22.5408 15.3943 22.8978 16.4485 20.5758C16.5678 20.1213 16.6275 19.6031 16.6275 19.0213V6.40039H19.8575Z"
      fill={color}
    />
  </svg>
)

export default TikTokIcon
