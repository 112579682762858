import dayjs from "dayjs"
import React from "react"
import { twMerge } from "tailwind-merge"
import { useQuery } from "urql"

const NEXT_AVAILABLE_SLOT_QUERY = `
  query NextAvailableSlot($serviceId: ID!) {
    nextAvailableSlot(serviceId: $serviceId)
  }
`

const formatDateTime = (dateTime) => {
  const now = dayjs()
  const inputDate = dayjs(dateTime)
  const daysFromNow = inputDate.diff(now, "days")

  let formattedTime = inputDate.format("h:mma") // e.g. 8:00am

  if (daysFromNow === 0) {
    return `Today @ ${formattedTime}`
  } else if (daysFromNow === 1) {
    return `Tomorrow @ ${formattedTime}`
  } else if (daysFromNow > 1 && daysFromNow <= 6) {
    return `${inputDate.format("dddd")} @ ${formattedTime}`
  } else {
    return inputDate.format("MMM D @ ") + formattedTime
  }
}

const NextAvailableSlot = ({ service, className }) => {
  const [{ data, fetching, error }] = useQuery({
    query: NEXT_AVAILABLE_SLOT_QUERY,
    variables: { serviceId: service.id }
  })

  const nextAvailableSlot = data?.nextAvailableSlot

  if (fetching || !nextAvailableSlot) return null
  if (error) console.error(error) // eslint-disable-line no-console

  return (
    <div className={twMerge("flex gap-1 sm:flex-col", className)}>
      <div>Next available:</div>
      <div className="font-bold">{formatDateTime(nextAvailableSlot)}</div>
    </div>
  )
}

export default NextAvailableSlot
