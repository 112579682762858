import React, { useState } from "react"

import { Button } from "../../components/shared/Buttons"
import { InputWithLabel } from "../../components/shared/Inputs"
import { urqlClient } from "../../utils/utils"

const PhoneValidation = ({ onSuccess }) => {
  const [phone, setPhone] = useState("")
  const [validationError, setValidationError] = useState(null)

  const sendVerification = () => {
    if (!phone.trim()) {
      setValidationError("Please enter your phone number")
      return
    }

    const formattedPhone = phone.replace(/\D/g, "")
    const e164Phone = formattedPhone.startsWith("1") ? `+${formattedPhone}` : `+1${formattedPhone}`

    urqlClient
      .mutation(
        `mutation($phone: String!) {
          phoneVerification(phone: $phone) { result errors }
        }`,
        { phone: e164Phone }
      )
      .toPromise()
      .then((result) => {
        if (result.data?.phoneVerification?.result === "success") {
          onSuccess()
        } else {
          setValidationError(result.data?.phoneVerification?.errors?.[0] || "Failed to send verification code")
        }
      })
      .catch((error) => {
        console.log(error)
        setValidationError("Failed to send verification code. Please try again.")
      })
  }

  return (
    <div>
      <InputWithLabel label="Phone" type="tel" value={phone} onChange={(e) => setPhone(e.target.value)} />
      {validationError && <p className="text-sm text-red">{validationError}</p>}
      <Button onClick={sendVerification} className="w-full">
        Send Verification
      </Button>
    </div>
  )
}

export default PhoneValidation
