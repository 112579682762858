import { AcademicCapIcon, CheckBadgeIcon } from "@heroicons/react/24/outline"
import { CheckBadgeIcon as CheckBadgeSolidIcon } from "@heroicons/react/24/solid"
import dayjs from "dayjs"
import capitalize from "lodash/capitalize"
import React from "react"

import HoverPopover from "../../components/shared/HoverPopover"
import Typography from "../../components/shared/Typography"

import ScrollToSection from "./ScrollToSection"

export const LicensePopover = ({ proCredential, practitioner }) => (
  <div className="relative h-[240px] w-[480px] sm:h-fit sm:w-full">
    <img
      className="absolute left-0 top-6 h-[120px] w-[120px] rounded-full border-2 border-white sm:static sm:mx-auto sm:block"
      src={practitioner.profilePhotoUrl}
      alt={practitioner.firstName}
    />
    <CheckBadgeSolidIcon className="absolute left-[40px] top-[122px] h-[40px] w-[40px] text-teal sm:static sm:mx-auto sm:mt-[-24px]" />
    <CheckBadgeIcon className="absolute left-[39px] top-[121px] h-[42px] w-[42px] text-white sm:static sm:mx-auto sm:mt-[-41px]" />
    <img
      src="/images/email/logo--center.png"
      alt="Heal.me"
      className="absolute bottom-0 left-0 h-[40px] w-[40px] sm:hidden"
    />
    <div className="-ml-6 -mt-6 box-content h-[48px] w-full rounded-t-xl bg-gray-ultralight p-6 sm:hidden">
      <div className="absolute left-[150px] top-6">
        <div className="font-bold">{proCredential.credential.name}</div>
        <div>
          State of {capitalize(proCredential.stateIssued)} #{proCredential.licenseNumber}
        </div>
      </div>
    </div>
    <div className="mt-4 hidden sm:block">
      <div className="font-bold">{proCredential.credential.name}</div>
      <div>
        State of {capitalize(proCredential.stateIssued)} #{proCredential.licenseNumber}
      </div>
    </div>
    <div className="absolute left-[150px] top-[96px] mt-6 grid w-[330px] grid-cols-2 grid-rows-2 justify-between sm:static sm:mt-4">
      <div className="sm:border-t sm:border-gray-light sm:py-4">
        <Typography variant="smSubtitle" className="font-bold">
          First name
        </Typography>
        <div>{practitioner.firstName}</div>
      </div>
      <div className="sm:border-t sm:border-gray-light sm:py-4">
        <Typography variant="smSubtitle" className="font-bold">
          Last name
        </Typography>
        <div>{practitioner.lastName}</div>
      </div>
      <div className="border-t border-gray-light pt-4">
        {proCredential.expiresAt && (
          <>
            <Typography variant="smSubtitle" className="font-bold">
              Expiration date
            </Typography>
            <div>{dayjs(proCredential.expiresAt).format("MM/DD/YYYY")}</div>
          </>
        )}
      </div>
      <div className="border-t border-gray-light pt-4"></div>
    </div>
    <div className="hidden sm:block">
      <Typography variant="smSubtitle">
        Heal.me has verified Lauren’s name, identity, and license with the State of{" "}
        {capitalize(proCredential.stateIssued)}.
      </Typography>
    </div>
  </div>
)

export const CredentialPopover = ({ proCredential }) => (
  <div className="relative flex h-[496px] w-[496px] items-center justify-center bg-gray-ultralight sm:h-fit sm:w-full">
    {proCredential.filestackDocumentName.endsWith(".pdf") ? (
      <iframe className="h-full max-h-full w-full max-w-full" src={proCredential.filestackDocument} />
    ) : (
      <img
        className="max-h-full max-w-full"
        src={proCredential.filestackDocument}
        alt={proCredential.credential.name}
      />
    )}
  </div>
)

const CredentialsSection = ({ proCredentials, practitioner }) => (
  <ScrollToSection id="credentials" className="mb-0 border-none pb-8">
    <Typography variant="h4" as="h2">
      Credentials
    </Typography>
    <Typography variant="subtitle">Heal.me verifies all credentials before publishing.</Typography>
    <div className="mt-4 grid grid-cols-2 gap-2 sm:grid-cols-1">
      {proCredentials.map((proCredential) => (
        <div key={`credential-${proCredential.credential.name}`} className="flex items-start gap-2">
          {proCredential.kind === "degree" ? (
            <AcademicCapIcon className="h-6 w-6 flex-none text-gray-dark" />
          ) : (
            <CheckBadgeIcon className="h-6 w-6 flex-none text-gray-dark" />
          )}
          <div>
            {proCredential.kind === "license" || proCredential.filestackDocumentName ? (
              <HoverPopover
                credentialKind={proCredential.kind}
                popoverContent={
                  proCredential.kind === "license" ? (
                    <LicensePopover proCredential={proCredential} practitioner={practitioner} />
                  ) : (
                    <CredentialPopover proCredential={proCredential} />
                  )
                }>
                <span className="cursor-pointer border-b-2 border-dashed border-gray-light">
                  {proCredential.credential.name}
                </span>
              </HoverPopover>
            ) : (
              <a
                className="border-b-2 border-dashed border-gray-light"
                target="_blank"
                href={proCredential.filestackDocument}
                rel="noreferrer">
                {proCredential.credential.name}
              </a>
            )}
            {proCredential.licenseNumber && proCredential.stateIssued && (
              <Typography variant="smSubtitle">
                State of {capitalize(proCredential.stateIssued)} #{proCredential.licenseNumber}
              </Typography>
            )}
          </div>
        </div>
      ))}
    </div>
  </ScrollToSection>
)

export default CredentialsSection
