import React from "react"
import { twMerge } from "tailwind-merge"

import useIntersectionObserver from "../../hooks/useIntersectionObserver"
import AboutMeContainer from "../ProProfileShared/AboutMeContainer"
import { DEFAULT_HIGHLIGHT_CLASSES } from "../WebsiteBuilder/WebsiteBuilderPage"

export const nl2br = (text) => text?.replace(/>\n/g, ">")?.replace(/\n/g, "<br />")

const AboutSection = ({ practice, theme, highlight, className, viewingInBuilder }) => {
  const [isVisible, containerRef] = useIntersectionObserver()

  return (
    <section
      id="about"
      style={{ color: theme.colorTextDark, backgroundColor: theme.colorSecondary }}
      className={twMerge("py-24 lg:px-32 lg:pb-8 md:px-12 sm:px-0", className)}>
      <div className="relative mx-auto flex items-center lg:block" ref={containerRef}>
        <img
          src={practice.user.profilePhotoUrl}
          alt={practice.user.name}
          className={twMerge(
            "right-[60%] aspect-square w-[782px] transition-all duration-[1500ms] lg:relative lg:right-unset lg:z-20 lg:mx-auto lg:-mb-4 lg:w-[240px]",
            highlight === "Headshot" ? DEFAULT_HIGHLIGHT_CLASSES : "",
            isVisible
              ? "translate-x-0 translate-y-0 opacity-100"
              : "opacity-0 min_sm:-translate-x-full min_sm:-translate-y-full"
          )}
        />
        <AboutMeContainer
          practice={practice}
          theme={theme}
          isVisible={isVisible}
          className="absolute left-[45%] z-10 w-[760px] lg:static lg:w-full"
          cardBackgroundColor={theme.colorGreyLightest}
          viewingInBuilder={viewingInBuilder}
          credentialsGridClassName="grid-cols-2 lg:grid-cols-1"
          credentialsClassName="p-6"
        />
      </div>
    </section>
  )
}

export default AboutSection
