const practiceFreeService = (practice) => practice.services.find((service) => service.amountCents === 0)

export const practiceCtaText = (practice) => {
  if (practice.ctaButtonText) {
    return practice.ctaButtonText
  }
  const freeService = practiceFreeService(practice)

  return freeService ? "Book a free discovery call" : "Book now"
}

export const practiceDefaultServiceId = (practice) => {
  if (practice.defaultServiceId) {
    return practice.defaultServiceId
  }
  const freeService = practiceFreeService(practice)

  return freeService ? freeService.id : null
}

export const getFirstAvailableTimeSlot = (allAvailability) => {
  for (let i = 0; i < allAvailability.length; i++) {
    if (allAvailability[i].datetimes.length > 0) {
      return allAvailability[i].datetimes[0]
    }
  }
}
