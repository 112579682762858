import PropTypes from "prop-types"
import React from "react"
import { twMerge } from "tailwind-merge"

const Badge = ({ type = "success", className, children }) => {
  const typeClassName =
    type === "warning"
      ? "bg-orange-light"
      : type === "error"
      ? "bg-red-light"
      : type === "info"
      ? "bg-gray"
      : "bg-green-light text-green" // success is the default
  return (
    <span
      className={twMerge(
        "rounded-lg px-2.5 py-1 text-[12px] font-extrabold uppercase tracking-[0.045rem] ",
        typeClassName,
        className
      )}>
      {children}
    </span>
  )
}

Badge.propTypes = {
  type: PropTypes.oneOf(["success", "warning", "error", "info"])
}

export const CounterBadge = ({ className, children }) => (
  <span
    className={twMerge(
      "inline-flex items-center justify-center rounded-full bg-orange text-white",
      "text-[12px] font-bold uppercase",
      "h-4 w-4",
      "leading-4",
      "p-0",
      className
    )}>
    {children}
  </span>
)

CounterBadge.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired
}

export default Badge
