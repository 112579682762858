import React, { useState } from "react"
import { twMerge } from "tailwind-merge"
import { useMutation } from "urql"

import { Button } from "../../components/shared/Buttons"
import { Input, InputWithLabel, Label, TextArea } from "../../components/shared/Inputs"
import { AnimatedModal } from "../../components/shared/Modal"
import Typography from "../../components/shared/Typography"
import { useCurrentUser } from "../../contexts/CurrentUserContext"
import { useToast } from "../../contexts/ToastContext"

const PHONE_AUTH_MUTATION = `
  mutation($phone: String!) {
    phoneVerification(phone: $phone) { result errors }
  }
`

const PHONE_LOGIN_MUTATION = `
  mutation($phone: String!, $code: String!, $device: String!, $firstName: String, $email: String) {
    phoneLogin(phone: $phone, code: $code, device: $device, firstName: $firstName, email: $email) {
      result
      token
      errors
    }
  }
`

const SEND_MESSAGE_MUTATION = `
  mutation SendMessage($practitionerId: ID!, $message: String!) {
    sendMessage(practitionerId: $practitionerId, message: $message) {
      result
      errors
    }
  }
`

const AskQuestionButton = ({ practice, className }) => {
  const [showQuestionModal, setShowQuestionModal] = useState(false)
  const [showOtpInput, setShowOtpInput] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const [firstName, setFirstName] = useState("")
  const [phone, setPhone] = useState("")
  const [code, setCode] = useState("")
  const [email, setEmail] = useState("")
  const [question, setQuestion] = useState("")
  const [validationError, setValidationError] = useState(null)
  const { user, fetchUser } = useCurrentUser()
  const { showToast } = useToast()

  const [{ fetching: phoneVerificationFetching, error: phoneVerificationError }, phoneVerification] =
    useMutation(PHONE_AUTH_MUTATION)
  const [{ fetching: phoneLoginFetching, error: phoneLoginError }, phoneLogin] = useMutation(PHONE_LOGIN_MUTATION)
  const [{ fetching: sendMessageFetching, error: sendMessageError }, sendMessage] = useMutation(SEND_MESSAGE_MUTATION)
  const error = phoneVerificationError || phoneLoginError || sendMessageError
  const fetching = phoneVerificationFetching || phoneLoginFetching || sendMessageFetching || submitting

  const closeModal = () => {
    setFirstName("")
    setPhone("")
    setCode("")
    setQuestion("")
    setValidationError(null)
    setShowOtpInput(false)
    setShowQuestionModal(false)
    setSubmitting(false)
  }

  const submitMessage = () => {
    sendMessage({ practitionerId: practice.user.id, message: question }).then((result) => {
      if (result.data?.sendMessage?.result === "success") {
        closeModal()
        showToast("Your message has been sent! 🎉")
      } else {
        setValidationError(result.data?.sendMessage?.errors.toString() || "There was an error submitting your question")
        console.error(result)
      }
    })
  }

  const onSave = () => {
    if (user) {
      submitMessage()
    } else if (showOtpInput) {
      setSubmitting(true)
      const variables = { phone, code, firstName, device: "web" }
      if (email) variables.email = email
      phoneLogin(variables).then((result) => {
        if (result.data?.phoneLogin?.result === "success") {
          fetchUser()
          submitMessage()
        } else {
          setSubmitting(false)
          setValidationError(result.data?.phoneLogin?.errors.toString() || "There was an error submitting your code")
          console.error(result)
        }
      })
    } else {
      phoneVerification({ phone }).then((result) => {
        if (result.data?.phoneVerification?.result === "success") {
          setShowOtpInput(true)
        } else {
          setValidationError(
            result.data?.phoneVerification?.errors.toString() ||
              "There was an error submitting your name and phone number"
          )
          console.error(result)
        }
      })
    }
  }

  if (error) console.error(error)

  return (
    <>
      <Button
        type="secondary"
        size="large"
        className={twMerge("sm:w-full", className)}
        onClick={() => setShowQuestionModal(true)}>
        Ask {practice.user.firstName} a question
      </Button>
      <AnimatedModal
        visible={showQuestionModal}
        hideModal={closeModal}
        header={`Ask ${practice.user.firstName} a question`}
        showFooter={true}
        actionButtonCopy="Ask question"
        onSave={onSave}
        saveDisabled={fetching}>
        <div className="flex flex-col gap-4">
          <div className="text-gray-dark">
            {showOtpInput
              ? "Enter the 6 digit code we sent to your phone to log in and finish sending your message."
              : "Typically responds in 24 hours."}
          </div>
          {validationError && (
            <div>
              <span className="text-red">{validationError}</span>{" "}
              <button
                className="font-bold text-teal underline"
                onClick={() => {
                  setValidationError("")
                  setCode("")
                  setShowOtpInput(false)
                }}>
                Update info
              </button>
            </div>
          )}
          {showOtpInput ? (
            <div className="flex justify-center">
              <InputWithLabel className="w-1/2" label="Code" value={code} onChange={(e) => setCode(e.target.value)} />
            </div>
          ) : (
            <>
              {!user && (
                <>
                  <div className="flex gap-4 sm:flex-col">
                    <InputWithLabel
                      className="w-1/2 sm:w-full"
                      label="First name"
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                    />
                    <InputWithLabel
                      className="w-1/2 sm:w-full"
                      type="phone"
                      label="Phone"
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                    />
                  </div>
                  <div>
                    <div className="flex justify-between">
                      <Label htmlFor="email">Email</Label>
                      <div className="text-sm text-gray-dark">Optional</div>
                    </div>
                    <Input
                      id="email"
                      type="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      explanatorySubtext={`Not required, but ${practice.user.firstName} will respond to you via text message or phone call.`}
                    />
                  </div>
                </>
              )}
              <div>
                <Label htmlFor="question">Your question</Label>
                <TextArea id="question" value={question} onChange={(e) => setQuestion(e.target.value)} />
                <Typography variant="micro" className="-mt-0.5" as="div">
                  Do not share personal health information. Please book a discovery call for that.
                </Typography>
              </div>
            </>
          )}
        </div>
      </AnimatedModal>
    </>
  )
}

export default AskQuestionButton
