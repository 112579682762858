import React, { useState } from "react"

import { Button } from "../../components/shared/Buttons"
import EndorsementsSection from "../ProProfile/EndorsementsSection"
import ReviewCard from "../ProProfile/ReviewCard"
import ScrollToSection, { scrollToId } from "../ProProfile/ScrollToSection"

const MyReviewsSection = ({ practice }) => {
  const reviews = practice.reviews
  const skills = practice.skills
  const practitionerName = practice.user.name

  const [showAllReviews, setShowAllReviews] = useState(false)
  const displayedReviews = showAllReviews ? reviews : reviews.slice(0, 10)

  return (
    <ScrollToSection id="reviews" className="mb-0 border-none pb-0">
      <div className="mx-auto max-w-4xl py-12">
        <h2 className="mb-1 text-center font-profilePage text-2xl">MY REVIEWS</h2>
        <div className="mb-8 flex flex-row items-center justify-center gap-2">
          <img src="/images/healme_logo.svg" alt="Logo" className="h-6 w-6 rounded border border-gray-dark p-1" />
          <div className="text-sm text-gray-dark ">Heal.me verified</div>
        </div>
        <div className="flex justify-center">
          <EndorsementsSection
            skills={skills}
            showAllButton={true}
            count={10}
            className="items-center"
            listClasses="justify-center"
            buttonClasses="border-none underline text-sm focus:ring-0 active:bg-transparent"
          />
        </div>
        <div className="mt-6 flex flex-col gap-4">
          {displayedReviews.map((review) => (
            <ReviewCard key={`review-${review.id}`} review={review} practitionerName={practitionerName} />
          ))}
          {reviews.length > 10 && (
            <Button
              type="tertiary"
              className="w-fit border-none text-sm underline focus:ring-0 active:bg-transparent sm:w-full"
              onClick={(e) => {
                setShowAllReviews(!showAllReviews)
                if (showAllReviews) {
                  scrollToId(e, "reviews")
                }
              }}>
              {showAllReviews ? "See less" : "See all reviews"}
            </Button>
          )}
        </div>
      </div>
    </ScrollToSection>
  )
}

export default MyReviewsSection
