import React, { useEffect, useState } from "react"
import { twMerge } from "tailwind-merge"

const AnimatingBackgroundImage = ({
  url,
  children,
  className,
  style,
  animationPixels = 100,
  animationDuration = 250,
  ...rest
}) => {
  const [offsetY, setOffsetY] = useState(0)

  useEffect(() => {
    let intervalId
    const startTime = Date.now()

    const incrementOffset = () => {
      const elapsedTime = Date.now() - startTime
      const progress = Math.min(elapsedTime / animationDuration, 1)

      setOffsetY(animationPixels * progress)

      if (progress >= 1) {
        clearInterval(intervalId)
      }
    }

    intervalId = setInterval(incrementOffset, 16) // ~60fps

    return () => clearInterval(intervalId)
  }, [animationPixels, animationDuration])

  return (
    <div
      {...rest}
      className={twMerge("bg-cover bg-no-repeat bg-blend-multiply", className)}
      style={{
        ...style,
        backgroundImage: `url(${url})`,
        backgroundPosition: `center ${-animationPixels + offsetY}px`
      }}>
      {children}
    </div>
  )
}

export default AnimatingBackgroundImage
