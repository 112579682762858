import { WebAppBookNow } from "@heal-me/healme-widget"
import React, { useEffect, useState } from "react"

import ReviewStars from "../../components/shared/ReviewStars"
import { scrollToAbout, scrollToCredentials, scrollToReviews, scrollToServices } from "../ProProfileV2"

function StickyHeader({ practitioner, practice, isVisible }) {
  return (
    <div
      className={`fixed top-0 z-50 w-full bg-blue-dark/80 backdrop-blur-lg transition-transform duration-300 ${
        isVisible ? "translate-y-0 opacity-100" : "-translate-y-full opacity-0"
      }`}>
      <div className="mx-auto flex max-w-7xl flex-row items-center p-8 text-white md:px-6">
        <div className="max-w-[50%]">
          <div className="font-profilePage text-2xl">{practitioner.name}</div>
        </div>
        <div className="ml-auto flex flex-row items-center min_md:gap-8">
          <button onClick={scrollToAbout} className="md:hidden">
            About
          </button>
          <button onClick={scrollToServices} className="md:hidden">
            Services
          </button>
          <button onClick={scrollToReviews} className="md:hidden">
            Reviews
          </button>
          <button onClick={scrollToCredentials} className="md:hidden">
            Credentials
          </button>
          <WebAppBookNow
            practiceId={practice.id}
            buttonCopy="Book now"
            buttonClasses="rounded-lg border-pink bg-pink font-bold px-4 py-1"
          />
        </div>
      </div>
    </div>
  )
}

export default function HeroSection({ practice }) {
  const practitioner = practice.user
  const reviews = practice.reviews
  const roundedAverageRating = Math.round(practice.averageRating)

  const hasFreeConsultation = practice.services.some((service) => service.amountCents === 0)

  const ctaText = hasFreeConsultation ? "Book your free discovery call" : "Book your appointment now"

  const [showStickyHeader, setShowStickyHeader] = useState(false)

  const fontSize = "clamp(24px, 24px + 2.25vw, 56px)"

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 200) {
        setShowStickyHeader(true)
      } else {
        setShowStickyHeader(false)
      }
    }

    window.addEventListener("scroll", handleScroll)
    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [])

  let heroImgUrl =
    practice.galleryImages?.length > 0 && practice.galleryImages[0].filestackPhoto?.large?.webp
      ? `https://cdn.filestackcontent.com/${practice.galleryImages[0].filestackPhoto?.large?.webp}`
      : "/images/homepage/hero_images/image-5.webp"

  return (
    <>
      <StickyHeader practitioner={practitioner} practice={practice} isVisible={showStickyHeader} />
      <section className="min-h-[50vh] md:mb-10">
        <div
          className="mb-10 min-h-[50vh] bg-cover bg-center bg-no-repeat bg-blend-multiply sm:mb-5"
          style={{ backgroundImage: `url(${heroImgUrl})` }}>
          <div className="h-full min-h-[50vh] w-full bg-purple bg-opacity-[0.64] backdrop-blur-md">
            <div className="mx-auto flex max-w-7xl flex-row items-center p-8 text-white md:px-6">
              <div className="max-w-[50%]">
                <div className="font-profilePage text-2xl font-bold">{practitioner.name}</div>
                <div>{practitioner.title}</div>
              </div>
              <div className="ml-auto flex flex-row items-center min_md:gap-8">
                <button onClick={scrollToAbout} className="md:hidden">
                  About
                </button>
                <button onClick={scrollToServices} className="md:hidden">
                  Services
                </button>
                <button onClick={scrollToReviews} className="md:hidden">
                  Reviews
                </button>
                <button onClick={scrollToCredentials} className="md:hidden">
                  Credentials
                </button>
                <WebAppBookNow
                  practiceId={practice.id}
                  buttonCopy="Book now"
                  buttonClasses="rounded-lg border-pink bg-pink font-bold px-4 py-1"
                />
              </div>
            </div>
            <div className="mx-auto flex h-full max-w-splash-container flex-row items-center justify-center hplg:px-8 md:flex-col min_md:pb-10 sm:px-6">
              <div className="flex max-w-3xl flex-col items-start gap-4 md:pb-16 min_md:w-1/2 min_md:py-24">
                <div className="font-profilePage font-bold leading-tight text-white" style={{ fontSize }}>
                  {practice.headline}
                </div>
                <WebAppBookNow
                  practiceId={practice.id}
                  buttonCopy={ctaText}
                  buttonClasses="md:w-full rounded-lg border-pink bg-pink font-bold px-8 py-4 text-white"
                />
                {reviews && reviews.length > 0 && (
                  <div className="flex items-center gap-2">
                    <ReviewStars rating={roundedAverageRating} />
                    <div>
                      <button className="font-bold text-white underline" onClick={scrollToReviews}>
                        {reviews.length} reviews
                      </button>
                    </div>
                  </div>
                )}
              </div>
              <div className="flex justify-end md:-mb-10 min_md:ml-10 min_md:w-1/2">
                <img src={heroImgUrl} alt="Gallery image" className="w-full max-w-[480px]" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
