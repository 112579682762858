import React from "react"

import Badge from "../../components/shared/Badge"
import ExpandableText from "../../components/shared/ExpandableText"
import { Box } from "../../components/shared/Layout"

import ReviewCardSignature from "./ReviewCardSignature"

const ReviewCard = ({ review, practitionerName }) => {
  const reviewText = review.review?.trim()

  return (
    <Box keepBorderInMobile={true} className="flex flex-col gap-4 break-words p-6">
      {reviewText && <ExpandableText text={reviewText} maxLength={240} quotes={true} />}
      <ReviewCardSignature review={review} />
      {review.timesBooked > 1 && (
        <Badge type="info" className="w-fit bg-gray-light">
          Booked {review.timesBooked} times
        </Badge>
      )}
      {review.reviewReply && (
        <div className="flex gap-4">
          <div className="w-1 flex-none rounded bg-gray text-gray">|</div>
          <div>
            <div className="text-sm font-bold">Reply from {practitionerName}</div>
            <div>&ldquo;{review.reviewReply.message}&rdquo;</div>
          </div>
        </div>
      )}
    </Box>
  )
}

export default ReviewCard
