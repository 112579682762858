import { WebAppBookNow } from "@heal-me/healme-widget"
import React from "react"
import { twMerge } from "tailwind-merge"

import AnimatedSection from "./AnimatedSection"
import SectionTitle from "./SectionTitle"
import ServiceCard from "./ServiceCard"

const ServicesSection = ({
  id = "services",
  practice,
  theme,
  viewingInBuilder,
  showTitle = true,
  includeImage = false,
  buttonLeft = false,
  hideImageInMobile = false,
  animateTitle = false,
  backgroundColor,
  cardBackgroundColor,
  className,
  titleClassName,
  imageClassName,
  mobileImageClassName
}) => {
  backgroundColor ||= theme.colorBgLight
  cardBackgroundColor ||= theme.colorGreyLightest
  const isMobile = window.innerWidth < 768
  const numServicesShown = isMobile ? 4 : includeImage ? 5 : 6
  const services = practice.services.slice(0, numServicesShown)
  if (includeImage) {
    services.splice(1, 0, { id: "image", url: practice.servicesSectionImageUrl })
  }

  return (
    <section id={id} style={{ backgroundColor }} className={twMerge("w-full py-24 text-center md:py-16", className)}>
      {!hideImageInMobile && (
        <div className="hidden md:block">
          <img
            src={practice.servicesSectionImageUrl}
            alt="Practice"
            className={twMerge("mb-16 w-full object-cover px-5", mobileImageClassName)}
          />
        </div>
      )}
      <div className="container mx-auto md:px-5">
        {showTitle && (
          <>
            {animateTitle ? (
              <AnimatedSection>
                <SectionTitle theme={theme} className={titleClassName}>
                  My Services
                </SectionTitle>
              </AnimatedSection>
            ) : (
              <SectionTitle theme={theme} className={titleClassName}>
                My Services
              </SectionTitle>
            )}
          </>
        )}
        <div className="my-10 md:mb-0">
          <AnimatedSection className="grid grid-cols-2 gap-10 lg:grid-cols-1">
            {services.map((service) => (
              <React.Fragment key={service.id}>
                {service.id === "image" ? (
                  <div className="relative md:hidden">
                    <img
                      src={service.url}
                      alt="Practice"
                      className={twMerge("h-[328px] w-full object-cover", imageClassName)}
                    />
                  </div>
                ) : (
                  <ServiceCard
                    key={service.id}
                    service={service}
                    practice={practice}
                    theme={theme}
                    viewingInBuilder={viewingInBuilder}
                    cardBackgroundColor={cardBackgroundColor}
                  />
                )}
              </React.Fragment>
            ))}
          </AnimatedSection>
          {practice.services.length > numServicesShown && (
            <div className={`mt-10 ${buttonLeft ? "text-left md:text-center" : "mx-auto"}`}>
              <WebAppBookNow
                practiceId={practice.id}
                buttonCopy={`See all ${practice.services.length} services`}
                buttonClasses={twMerge(
                  "border px-10 py-3 hover:bg-[var(--hover-color)] hover:text-white",
                  theme.buttonStyle === "rounded" ? "rounded-full" : "rounded",
                  viewingInBuilder ? "pointer-events-none" : ""
                )}
                style={{
                  "--hover-color": theme.colorAccent,
                  borderColor: theme.colorAccent,
                  color: theme.colorAccent
                }}
              />
            </div>
          )}
        </div>
      </div>
    </section>
  )
}

export default ServicesSection
