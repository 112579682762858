import React, { useEffect } from "react"

import ExpandableText from "../components/shared/ExpandableText"
import { CurrentUserProvider } from "../contexts/CurrentUserContext"
import { ToastProvider } from "../contexts/ToastContext"
import useWindowSize from "../hooks/useWindowSize"
import GraphQLProvider from "../providers/GraphQLProvider"

import AboutSection from "./ProProfile/AboutSection"
import BookingWidget from "./ProProfile/BookingWidget"
import CredentialsSection from "./ProProfile/CredentialsSection"
import DesktopHeaderSection from "./ProProfile/DesktopHeaderSection"
import EndorsementsSection from "./ProProfile/EndorsementsSection"
import GalleryImagesSection from "./ProProfile/GalleryImagesSection"
import LocationSection from "./ProProfile/LocationSection"
import MobileHeaderSection from "./ProProfile/MobileHeaderSection"
import ReviewsHeaderSection from "./ProProfile/ReviewsHeaderSection"
import ReviewsSection from "./ProProfile/ReviewsSection"
import { scrollToId } from "./ProProfile/ScrollToSection"
import ServicesSection from "./ProProfile/ServicesSection"
import WebsiteAndSocial from "./ProProfile/WebsiteAndSocial"

const ProProfile = ({ practice, currentUser }) => {
  const freeConsult = practice.services.find((service) => service.amountCents === 0)
  const selectedService = freeConsult || practice.services[0]
  const practitioner = practice.user
  const reviews = practice.reviews

  practice.skills =
    practice.skills.filter((skill) => skill.endorsementsCount > 0).length === 0
      ? practice.skills.sort((a, b) => a.position - b.position)
      : practice.skills
          .filter((skill) => skill.endorsementsCount > 0)
          .sort((a, b) => b.endorsementsCount - a.endorsementsCount || a.specialty.name.localeCompare(b.specialty.name))

  const { width } = useWindowSize()

  useEffect(() => {
    if (width > 0 && width < 640) {
      document.body.classList.remove("has-navbar-fixed-top")
      document.querySelector("nav").classList.remove("fixed")
    } else {
      document.body.classList.add("has-navbar-fixed-top")
      document.querySelector("nav").classList.add("fixed")
    }
  }, [width])

  const endorsementsCount = practice.skills.reduce((acc, skill) => acc + skill.endorsementsCount, 0)
  let seeAllCopy = "See all "
  if (practice.reviews.length > 0) {
    seeAllCopy += `${practice.reviews.length} reviews`
  }
  if (endorsementsCount > 0) {
    seeAllCopy += `${practice.reviews.length > 0 ? " & " : ""}${endorsementsCount} endorsements`
  }

  return (
    <GraphQLProvider>
      <ToastProvider>
        <CurrentUserProvider defaultUser={currentUser}>
          <main>
            <DesktopHeaderSection practice={practice} />
            <div className="mx-auto max-w-[1264px] p-8 md:px-6 md:pt-0">
              <MobileHeaderSection practice={practice} />
              <div className="flex w-full justify-between gap-4">
                <section className="max-w-[736px] flex-1 md:w-full">
                  <ReviewsHeaderSection
                    reviews={reviews}
                    practitionerName={practitioner.firstName}
                    skills={practice.skills}
                    seeAllCopy={seeAllCopy}
                  />
                  <div className="md:hidden">
                    <EndorsementsSection skills={practice.skills} showAllButton={false} count={8} />
                    {(practice.reviews.length > 0 || endorsementsCount > 0) && (
                      <button className="mt-4 font-bold text-teal underline" onClick={(e) => scrollToId(e, "reviews")}>
                        {seeAllCopy}
                      </button>
                    )}
                  </div>
                  {practice.galleryImages.length > 0 && <GalleryImagesSection images={practice.galleryImages} />}
                  {practice.about && (
                    <div className="mt-6">
                      <ExpandableText text={practice.about} maxLength={280} allowHtml={true} scrollUpOnClose={true} />
                    </div>
                  )}
                  <LocationSection practice={practice} />
                  <ServicesSection
                    practiceId={practice.id}
                    services={practice.services}
                    currency={practice.defaultCurrency}
                  />
                  <ReviewsSection
                    reviews={reviews}
                    skills={practice.skills}
                    averageRating={practice.averageRating}
                    practitionerName={practitioner.firstName}
                  />
                  <CredentialsSection proCredentials={practice.proCredentials} practitioner={practitioner} />
                  <AboutSection practice={practice} />
                  <WebsiteAndSocial practice={practice} />
                </section>
                {selectedService && <BookingWidget practice={practice} selectedService={selectedService} />}
              </div>
            </div>
          </main>
        </CurrentUserProvider>
      </ToastProvider>
    </GraphQLProvider>
  )
}

export default ProProfile
