import React from "react"

import { CurrentUserProvider } from "../contexts/CurrentUserContext"
import { ToastProvider } from "../contexts/ToastContext"
import GraphQLProvider from "../providers/GraphQLProvider"

import GalleryImagesSection from "./ProProfile/GalleryImagesSection"
import { scrollToId } from "./ProProfile/ScrollToSection"
import AboutMeSection from "./ProProfileV2/AboutMeSection"
import CTASection from "./ProProfileV2/CTASection"
import HeroSection from "./ProProfileV2/HeroSection"
import LocationSectionV2 from "./ProProfileV2/LocationSectionV2"
import MyCredentialsSection from "./ProProfileV2/MyCredentialsSection"
import MyPracticeSection from "./ProProfileV2/MyPracticeSection"
import MyReviewsSection from "./ProProfileV2/MyReviewsSection"
import MyServicesSection from "./ProProfileV2/MyServicesSection"
import ProProfileFooter from "./ProProfileV2/ProProfileFooter"

export const scrollToAbout = (e) => scrollToId(e, "about")
export const scrollToReviews = (e) => scrollToId(e, "reviews")
export const scrollToServices = (e) => scrollToId(e, "services")
export const scrollToCredentials = (e) => scrollToId(e, "credentials")

const ProProfileV2 = ({ practice, currentUser }) => {
  practice.skills =
    practice.skills.filter((skill) => skill.endorsementsCount > 0).length === 0
      ? practice.skills.sort((a, b) => a.position - b.position)
      : practice.skills
          .filter((skill) => skill.endorsementsCount > 0)
          .sort((a, b) => b.endorsementsCount - a.endorsementsCount || a.specialty.name.localeCompare(b.specialty.name))

  return (
    <GraphQLProvider>
      <ToastProvider>
        <CurrentUserProvider defaultUser={currentUser}>
          <main>
            <HeroSection practice={practice} />
            <MyServicesSection practice={practice} />
            <AboutMeSection practice={practice} />
            <div className="mx-auto max-w-7xl p-8 md:px-6 md:pt-0">
              {practice.galleryImages.length > 0 && (
                <GalleryImagesSection
                  images={practice.galleryImages}
                  sectionClasses="flex justify-center min_md:mt-0 mb-8"
                />
              )}
              <MyReviewsSection practice={practice} />
              <MyCredentialsSection practice={practice} />
              <MyPracticeSection practice={practice} />
              <LocationSectionV2 practice={practice} className="justify-center gap-4" showVirtualLocation={false} />
            </div>
            <CTASection practice={practice} />
            <ProProfileFooter practice={practice} />
          </main>
        </CurrentUserProvider>
      </ToastProvider>
    </GraphQLProvider>
  )
}

export default ProProfileV2
