import React from "react"

import ReviewStars from "../../components/shared/ReviewStars"
import Typography from "../../components/shared/Typography"

const ReviewCardSignature = ({ review, children }) => {
  const isAnonymous = review.isAnonymous
  const name = review.client?.firstName || review.user?.firstName || review.unverifiedName
  const lastName = review.client?.lastName || review.user?.lastName
  const firstName = name && name.split(" ")[0]
  const lastInitial = lastName ? `${lastName[0]}.`.toUpperCase() : ""

  return (
    <div className="flex w-full flex-wrap items-center gap-2">
      <Typography variant="small" as="div" className="hidden text-gray-dark sm:block">
        {isAnonymous ? "Anonymous" : firstName} • {review.createdAtAgoInWords}
      </Typography>
      <Typography variant="small" as="div" className="text-gray-dark sm:hidden">
        {isAnonymous ? "Anonymous" : `${firstName} ${lastInitial}`} • {review.createdAtAgoInWords}
      </Typography>
      <ReviewStars rating={review.rating} />
      {children}
    </div>
  )
}

export default ReviewCardSignature
