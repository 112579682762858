import { ChevronDownIcon } from "@heroicons/react/24/outline"
import React from "react"
import { twMerge } from "tailwind-merge"

import { scrollToId } from "../ProProfile/ScrollToSection"

import AnimatedSection from "./AnimatedSection"
import Review from "./Review"

const TopReviewsSection = ({ practice, theme, className, backgroundColor, color }) => {
  color ||= theme.colorTextDark
  backgroundColor ||= theme.colorPrimary
  const reviews = practice.reviews.filter((review) => review.review).slice(0, 2)
  const reviewsCount = practice.reviews.length

  return (
    <section className={twMerge("py-16 md:px-5", className)} style={{ color, backgroundColor }}>
      <div className="container relative mx-auto">
        <AnimatedSection className="grid grid-cols-2 gap-10 lg:grid-cols-1">
          {reviews.map((review) => (
            <Review
              key={`top-review-${review.id}`}
              review={review}
              practice={practice}
              showEndorsements={false}
              starColor={theme.colorReviewStars}
            />
          ))}
        </AnimatedSection>
        <div className="mt-8 text-center">
          <button
            onClick={(e) => scrollToId(e, "reviews")}
            style={{ color, "--hover-color": theme.colorAccent }}
            className="hover:text-[var(--hover-color)]">
            See {reviewsCount - 2} more
            <br />
            <ChevronDownIcon className="mx-auto h-6 w-6" />
          </button>
        </div>
      </div>
    </section>
  )
}

export default TopReviewsSection
