import React from "react"
import { twMerge } from "tailwind-merge"

import AdditionalContent from "../ProProfileShared/AdditionalContent"
import AnimatedSection from "../ProProfileShared/AnimatedSection"
import { DEFAULT_HIGHLIGHT_CLASSES } from "../WebsiteBuilder/WebsiteBuilderPage"

const ValueStatementSection = ({ practice, theme, highlight }) => {
  const { valueStatement } = practice

  return (
    <section
      id="value-statement"
      style={{ backgroundColor: theme.colorBgLight }}
      className="mx-auto pb-8 pt-20 md:pb-5">
      <AnimatedSection
        className={twMerge(
          "container mx-auto mt-10 py-8 text-center md:mt-0 md:pt-0",
          highlight === "valueStatement" ? DEFAULT_HIGHLIGHT_CLASSES : ""
        )}
        style={{ color: theme.colorTextDark }}>
        <AdditionalContent valueStatement={valueStatement} />
      </AnimatedSection>
      <div className="mx-auto flex max-w-[1362px] gap-8 md:w-full md:flex-col md:gap-5 md:px-5">
        <img
          src={practice.valueStatementImageUrl}
          alt={practice.user.name}
          className="w-[calc(50%-1rem)] object-cover md:w-full"
        />
        <img
          src={practice.valueStatementImageTwoUrl}
          alt={practice.user.name}
          className="w-[calc(50%-1rem)] object-cover md:w-full"
        />
      </div>
    </section>
  )
}

export default ValueStatementSection
