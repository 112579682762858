import { BuildingOfficeIcon, DevicePhoneMobileIcon } from "@heroicons/react/24/outline"
import { Circle, GoogleMap, InfoWindow, LoadScript, Marker } from "@react-google-maps/api"
import React, { useState } from "react"

import { formatPhone } from "../../utils/utils"
const travelDistanceInMeters = (distanceInMiles) => Number(distanceInMiles) * 1609.34

// There are approximately 69 miles in one latitude degree. The 0.9 comes from estimating the half the size of the
// icon we're using to hide address at map zoom level 11. This will not work properly if you change the map zoom level!
const HIDE_LOCATION_ICON_ADJUSTMENT = 0.9 / 69

const Map = ({ practice, theme, ClipPath, ClipPathTwo }) => {
  const [infoWindowLocation, setInfoWindowLocation] = useState(null)

  const physicalLocations = practice.locations.filter(
    (location) => location.kind === "office" || location.kind === "home"
  )
  if (physicalLocations.length === 0) return null

  let mapCenter, zoom
  if (physicalLocations.length === 1) {
    const location = physicalLocations[0]
    mapCenter = { lat: location.latitude, lng: location.longitude }
    zoom = location.kind === "home" ? 11 : !location.hideAddress ? 14 : 12
    if (location.hideAddress) {
      // northern hemisphere
      if (location.latitude > 0) {
        mapCenter.lat = mapCenter.lat + HIDE_LOCATION_ICON_ADJUSTMENT
      } else {
        mapCenter.lat = mapCenter.lat + HIDE_LOCATION_ICON_ADJUSTMENT
      }
    }
  } else {
    const latitudes = physicalLocations.map((location) => location.latitude).filter((lat) => lat !== null)
    const longitudes = physicalLocations.map((location) => location.longitude).filter((lng) => lng !== null)
    const minLatitude = Math.min(...latitudes)
    const maxLatitude = Math.max(...latitudes)
    const minLongitude = Math.min(...longitudes)
    const maxLongitude = Math.max(...longitudes)
    mapCenter = {
      lat: (minLatitude + maxLatitude) / 2,
      lng: (minLongitude + maxLongitude) / 2
    }
    const latDelta = maxLatitude - minLatitude
    const longDelta = maxLongitude - minLongitude
    const latZoom = Math.floor(Math.log2(360 / latDelta))
    const longZoom = Math.floor(Math.log2(360 / longDelta))
    if (latDelta === 0 || longDelta === 0) {
      zoom = 11
    } else {
      zoom = Math.min(latZoom, longZoom)
      zoom = Math.max(zoom, 5)
    }
  }

  return (
    <div className="relative h-[594px] w-full">
      <LoadScript googleMapsApiKey="AIzaSyBJzO3o3qx-4b53mVm0BA23J1z_Aj7MWmE">
        <GoogleMap
          mapContainerStyle={{ width: "100%", height: "100%" }}
          center={mapCenter}
          zoom={zoom}
          options={{ disableDefaultUI: true }}>
          {physicalLocations.map((location) => {
            const markerPosition = { lat: location?.latitude, lng: location?.longitude }

            return (
              <React.Fragment key={location.id}>
                {location.kind === "home" ? (
                  <Circle
                    center={markerPosition}
                    radius={travelDistanceInMeters(5)}
                    options={{ strokeColor: "#0D9DA4" }}
                  />
                ) : location.hideAddress ? (
                  <Marker position={markerPosition} icon="/defaults/m5.png" />
                ) : (
                  <Marker position={markerPosition} onClick={() => setInfoWindowLocation(location)} />
                )}
                {infoWindowLocation === location && (
                  <InfoWindow position={markerPosition} onCloseClick={() => setInfoWindowLocation(null)}>
                    <div className="flex flex-col gap-4 p-4 pt-0 text-[12px] text-gray-dark">
                      <div className="flex items-center gap-2">
                        <BuildingOfficeIcon className="h-4 w-4" />
                        <span>{location.address}</span>
                      </div>
                      {practice.user.phone && (
                        <div className="flex items-center gap-2">
                          <DevicePhoneMobileIcon className="h-4 w-4" />
                          <span>{formatPhone(practice.user.phone)}</span>
                        </div>
                      )}
                      <a
                        className="w-fit rounded border border-[var(--bg-color)] px-6 py-2 text-center"
                        style={{
                          color: theme.colorTextLight,
                          backgroundColor: theme.colorAccent,
                          "--bg-color": theme.colorAccent
                        }}
                        href={`https://maps.google.com/maps?daddr=${location.latitude},${location.longitude}`}
                        target="_blank"
                        rel="noopener noreferrer">
                        Get directions
                      </a>
                    </div>
                  </InfoWindow>
                )}
              </React.Fragment>
            )
          })}
        </GoogleMap>
      </LoadScript>
      {ClipPath && <ClipPath />}
      {ClipPathTwo && <ClipPathTwo />}
    </div>
  )
}

export default Map
