import { WebAppBookNow } from "@heal-me/healme-widget"
import React, { Fragment } from "react"

import Typography from "../../components/shared/Typography"
import { formatPrice } from "../../utils/utils"

import NextAvailableSlot from "./NextAvailableSlot"
import ScrollToSection from "./ScrollToSection"

const DesktopService = ({ service, practiceId, currency }) => {
  const displayText =
    service.description.length > 180 ? service.description.substring(0, 180 - 3) + "..." : service.description

  return (
    <div key={service.id} className="flex gap-8 sm:hidden">
      <div className="flex flex-1 flex-col gap-4">
        <Typography variant="body" className="font-bold" as="h4">
          {service.name}
        </Typography>
        <div>
          {service.package ? (
            <>
              <span className="text-teal">
                {formatPrice(service.amountCents)}
                {currency !== "USD" ? currency : ""}
              </span>
              <span className="text-gray-dark">
                {" "}
                / {service.numberOfSessions} sessions &bull; {service.timeLength} min{" "}
              </span>
            </>
          ) : (
            <>
              <span className="text-teal">
                {formatPrice(service.amountCents)} {currency == "CAD" ? " CAD" : ""}
              </span>{" "}
              <span className="text-gray-dark"> / {service.timeLength} min </span>
            </>
          )}
        </div>
        <div>
          {displayText}
          {service.description.length > 180 && (
            <WebAppBookNow
              practiceId={practiceId}
              serviceId={service.id}
              buttonCopy="See more"
              buttonClasses="font-bold underline"
              openServiceDetail={true}
            />
          )}
        </div>

        <div className="flex items-center gap-2 sm:flex-col sm:items-start sm:gap-2">
          <WebAppBookNow
            practiceId={practiceId}
            serviceId={service.id}
            buttonCopy={service.package ? "Buy Package" : "See availability"}
            buttonClasses="h-10 w-fit rounded border border-teal px-4 font-bold focus:outline-none focus:ring-2 focus:ring-blue border-teal bg-teal text-white sm:w-full hover:shadow active:bg-teal-dark"
          />
          {!service.package && <NextAvailableSlot service={service} />}
        </div>
      </div>
      {service.mediumWebpUrl && (
        <div className="flex-none">
          <img
            src={service.mediumWebpUrl}
            alt={service.name}
            className="h-[136px] w-[136px] rounded-lg sm:h-[80px] sm:w-[80px]"
          />
        </div>
      )}
    </div>
  )
}

const MobileService = ({ service, practiceId, currency }) => {
  const displayText =
    service.description.length > 180 ? service.description.substring(0, 180 - 3) + "..." : service.description

  return (
    <div key={service.id} className="hidden flex-col gap-4 sm:flex">
      <div>
        {service.mediumWebpUrl && (
          <img
            src={service.mediumWebpUrl}
            alt={service.name}
            className="float-right mb-8 ml-8 inline-block h-[80px] w-[80px] rounded-lg"
          />
        )}
        <Typography variant="body" className="mb-4 font-bold" as="h4">
          {service.name}
        </Typography>
        {displayText}
        {service.description.length > 180 && (
          <WebAppBookNow
            practiceId={practiceId}
            serviceId={service.id}
            buttonCopy="See more"
            buttonClasses="font-bold underline"
            openServiceDetail={true}
          />
        )}
      </div>
      {service.package ? (
        <div>
          <span className="text-teal">
            {formatPrice(service.amountCents)} {currency === "CAD" ? " CAD" : ""}
          </span>{" "}
          <span className="text-gray-dark">
            {" "}
            / {service.numberOfSessions} sessions &bull; {service.timeLength} min{" "}
          </span>
        </div>
      ) : (
        <div>
          <span className="text-teal">
            {formatPrice(service.amountCents)} {currency === "CAD" ? " CAD" : ""}
          </span>{" "}
          <span className="text-gray-dark"> / {service.timeLength} min </span>
        </div>
      )}
      <WebAppBookNow
        practiceId={practiceId}
        serviceId={service.id}
        buttonCopy="See availability"
        buttonClasses="h-10 w-fit rounded border border-teal px-4 font-bold focus:outline-none focus:ring-2 focus:ring-blue border-teal bg-teal text-white sm:w-full hover:shadow active:bg-teal-dark"
      />
      {!service.package && <NextAvailableSlot service={service} />}
    </div>
  )
}

const ServicesSection = ({ practiceId, services, currency }) => {
  const displayedServices = services.slice(0, 3)

  return (
    <ScrollToSection id="services" className="mt-4">
      <div className="flex flex-col gap-10 sm:gap-8">
        {displayedServices.map((service) => (
          <Fragment key={`service-${service.id}`}>
            <DesktopService service={service} practiceId={practiceId} currency={currency} />
            <MobileService service={service} practiceId={practiceId} currency={currency} />
          </Fragment>
        ))}
      </div>
      {services.length > 3 && (
        <WebAppBookNow
          practiceId={practiceId}
          buttonCopy="See all services"
          buttonClasses="rounded border px-4 font-bold focus:outline-none focus:ring-2 focus:ring-blue border-gray bg-white active:bg-gray-light h-8 sm:w-full mt-6"
        />
      )}
    </ScrollToSection>
  )
}

export default ServicesSection
