import React from "react"

import YoutubeIcon from "../../components/icons/YoutubeIcon"
import Typography from "../../components/shared/Typography"

const WebsiteAndSocial = ({ practice }) => {
  if (
    !practice.website &&
    !practice.facebook &&
    !practice.instagram &&
    !practice.twitter &&
    !practice.linkedin &&
    !practice.tiktok &&
    !practice.youtube
  ) {
    return null
  }

  return (
    <section className="mb-2">
      <Typography variant="h4" as="h2">
        Website & Social
      </Typography>
      <div className="mt-4 flex flex-wrap items-center gap-y-4">
        {practice.website && (
          <a
            target="_blank"
            className="mb-0 mr-2 block w-fit rounded-lg border border-gray bg-white px-4 font-bold leading-loose hover:shadow active:bg-gray-light sm:w-full"
            href={practice.website}
            rel="noreferrer">
            {practice.website.replace(/(^\w+:|^)\/\//, "")}
          </a>
        )}
        <div className="flex items-center sm:w-full sm:justify-center">
          {practice.instagram && (
            <a target="_blank" className="flex items-center justify-center" href={practice.instagram} rel="noreferrer">
              <img src="/images/icons/instagram.svg" alt="Instagram" />
            </a>
          )}
          {practice.tiktok && (
            <a target="_blank" className="flex items-center justify-center" href={practice.tiktok} rel="noreferrer">
              <img src="/images/icons/tiktok.svg" alt="TikTok" />
            </a>
          )}
          {practice.twitter && (
            <a target="_blank" className="flex items-center justify-center" href={practice.twitter} rel="noreferrer">
              <img src="/images/icons/twitter.svg" alt="Twitter" />
            </a>
          )}
          {practice.linkedin && (
            <a target="_blank" className="flex items-center justify-center" href={practice.linkedin} rel="noreferrer">
              <img src="/images/icons/linkedin.svg" alt="LinkedIn" />
            </a>
          )}
          {practice.facebook && (
            <a target="_blank" className="flex items-center justify-center" href={practice.facebook} rel="noreferrer">
              <img src="/images/icons/facebook.svg" alt="Facebook" />
            </a>
          )}
          {practice.youtube && (
            <a target="_blank" className="flex items-center justify-center" href={practice.youtube} rel="noreferrer">
              <YoutubeIcon />
            </a>
          )}
        </div>
      </div>
    </section>
  )
}

export default WebsiteAndSocial
