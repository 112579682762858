import React from "react"
import { twMerge } from "tailwind-merge"

import useIntersectionObserver from "../../hooks/useIntersectionObserver"

const AnimatedSection = ({ children, className, threshold = 0.1, ...rest }) => {
  const [isVisible, containerRef] = useIntersectionObserver(threshold)

  return (
    <div
      ref={containerRef}
      className={twMerge(
        "transition-all duration-1000",
        isVisible ? "translate-y-0 opacity-100" : "translate-y-24 opacity-0",
        className
      )}
      {...rest}>
      {children}
    </div>
  )
}

export default AnimatedSection
