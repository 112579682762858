import React from "react"
import { twMerge } from "tailwind-merge"

const ScrollToSection = ({ children, className, ...props }) => (
  <section
    className={twMerge("mb-[40px] scroll-mt-24 border-b border-gray-light pb-[40px] sm:scroll-mt-16", className)}
    {...props}>
    {children}
  </section>
)

export default ScrollToSection

export const scrollToId = (e, id) => {
  e.preventDefault()
  document.getElementById(id)?.scrollIntoView({ behavior: "smooth" })
  window.location.hash = id
}
