import { AcademicCapIcon, CheckBadgeIcon } from "@heroicons/react/24/outline"
import React from "react"
import { twMerge } from "tailwind-merge"

import HoverPopover from "../../components/shared/HoverPopover"
import Typography from "../../components/shared/Typography"
import { capitalize } from "../../utils/utils"
import { CredentialPopover, LicensePopover } from "../ProProfile/CredentialsSection"

const CredentialsSection = ({ practice, theme, className, gridClasses, backgroundColor, enablePopover = false }) => {
  backgroundColor ||= theme.colorBgLight

  return (
    <div className={twMerge("p-6", className)} style={{ backgroundColor }}>
      <div className={twMerge("grid grid-cols-1 gap-4", gridClasses)}>
        {practice.proCredentials.slice(0, 6).map((proCredential) => (
          <div key={`credential-${proCredential.credential.name}`} className="flex items-start gap-2">
            {proCredential.kind === "degree" ? (
              <AcademicCapIcon className="h-6 w-6 flex-none text-gray-dark" />
            ) : (
              <CheckBadgeIcon className="h-6 w-6 flex-none text-gray-dark" />
            )}
            <div>
              {enablePopover ? (
                <>
                  {proCredential.kind === "license" || proCredential.filestackDocumentName ? (
                    <HoverPopover
                      credentialKind={proCredential.kind}
                      popoverContent={
                        proCredential.kind === "license" ? (
                          <LicensePopover proCredential={proCredential} practitioner={practice.user} />
                        ) : (
                          <CredentialPopover proCredential={proCredential} />
                        )
                      }>
                      <span className="cursor-pointer border-b-2 border-dashed border-gray-light">
                        {proCredential.credential.name}
                      </span>
                    </HoverPopover>
                  ) : (
                    <a
                      className="border-b-2 border-dashed border-gray-light"
                      target="_blank"
                      href={proCredential.filestackDocument}
                      rel="noreferrer">
                      {proCredential.credential.name}
                    </a>
                  )}
                </>
              ) : (
                <span className="border-b-2 border-dashed border-gray-light">{proCredential.credential.name}</span>
              )}
              {proCredential.licenseNumber && proCredential.stateIssued && (
                <Typography variant="smSubtitle">
                  State of {capitalize(proCredential.stateIssued)} #{proCredential.licenseNumber}
                </Typography>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default CredentialsSection
