import React from "react"
import { twMerge } from "tailwind-merge"

import useIntersectionObserver from "../../hooks/useIntersectionObserver"
import AboutMeContainer from "../ProProfileShared/AboutMeContainer"
import { DEFAULT_HIGHLIGHT_CLASSES } from "../WebsiteBuilder/WebsiteBuilderPage"

const AboutSection = ({ practice, theme, highlight, className, viewingInBuilder }) => {
  const [isVisible, containerRef] = useIntersectionObserver()

  return (
    <section
      id="about"
      style={{ color: theme.colorTextDark, backgroundColor: theme.colorBgLight }}
      className={twMerge("mx-auto py-24 pt-32 lg:px-32 lg:py-0 md:px-12 sm:px-0", className)}>
      <div className="container relative mx-auto flex items-center lg:block" ref={containerRef}>
        <img
          src={practice.user.profilePhotoUrl}
          alt={practice.user.name}
          className={twMerge(
            "absolute -top-48 left-[50%] aspect-square w-3/5 transition-all duration-[1500ms] lg:static lg:w-full",
            highlight === "Headshot" ? DEFAULT_HIGHLIGHT_CLASSES : "",
            isVisible
              ? "translate-x-0 translate-y-0 opacity-100"
              : "opacity-0 min_sm:-translate-y-full min_sm:translate-x-full"
          )}
        />
        <AboutMeContainer
          practice={practice}
          theme={theme}
          isVisible={isVisible}
          className="z-10 lg:w-full min_md:w-3/5"
          cardBackgroundColor={theme.colorGreyLightest}
          viewingInBuilder={viewingInBuilder}
          credentialsGridClassName="grid-cols-2 lg:grid-cols-1"
          credentialsClassName="p-6"
          reverseAnimation={true}
        />
      </div>
    </section>
  )
}

export default AboutSection
