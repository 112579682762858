import React, { useState } from "react"

import { AnimatedModal } from "../../components/shared/Modal"
import ScrollToSection from "../ProProfile/ScrollToSection"

const nl2br = (text) => text.replace(/\n/g, "<br>")

const AboutMeSection = ({ practice }) => {
  const practitioner = practice.user

  const [isModalVisible, setIsModalVisible] = useState(false)

  const textLimit = 600
  const truncateText = (text) => {
    if (!text) return ""

    if (text.length > textLimit) {
      return text.slice(0, text.lastIndexOf(".", textLimit)) + "."
    }
    return text
  }

  const isTruncated = practice.about?.length > textLimit || false

  return (
    <ScrollToSection id="about" className="mb-0 border-none pb-0">
      <div className="flex flex-col items-center min_md:flex-row min_md:p-6">
        <div className="flex w-full justify-end min_md:w-1/2">
          <img
            src={practitioner.profilePhotoUrl}
            alt={practitioner.name}
            className="h-[500px] w-[500px] lg:h-full lg:w-full"
          />
        </div>
        <div className="pt-6 md:pt-0 min_md:w-1/2 ">
          <div className="bg-green-light p-16 min_md:-mt-16 min_md:max-w-[600px]">
            <h2 className="mb-4 text-2xl font-bold">About me</h2>
            <p
              dangerouslySetInnerHTML={{
                __html: nl2br(truncateText(practice.about))
              }}></p>
            {isTruncated && (
              <button onClick={() => setIsModalVisible(true)} className="mt-4 inline-block font-bold text-pink">
                Read more →
              </button>
            )}
          </div>
        </div>
      </div>
      {isTruncated && (
        <AnimatedModal
          visible={isModalVisible}
          hideModal={() => setIsModalVisible(false)}
          showFooter={false}
          header={"About me"}
          fullHeightInMobile={true}
          dialogClassName="overflow-auto max-h-[90vh]">
          <p
            dangerouslySetInnerHTML={{
              __html: nl2br(practice.about)
            }}></p>
        </AnimatedModal>
      )}
    </ScrollToSection>
  )
}

export default AboutMeSection
