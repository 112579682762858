import React from "react"
import { twMerge } from "tailwind-merge"

import { stripHTMLTags } from "../../utils/utils"
import { nl2br } from "../ProProfileThemeFive/AboutSection"

const AdditionalContent = ({ valueStatement }) => {
  if (!valueStatement) return null

  const characterCount = stripHTMLTags(valueStatement).length

  return (
    <div
      className={twMerge(
        "wysiwyg-content",
        characterCount < 400
          ? "wysiwyg-content-large"
          : characterCount < 720
          ? "wysiwyg-content-medium"
          : "wysiwyg-content-normal"
      )}
      dangerouslySetInnerHTML={{
        __html: nl2br(valueStatement)
      }}
    />
  )
}

export default AdditionalContent
