import React from "react"
import { twMerge } from "tailwind-merge"

const CurvyDividerReversed = ({ topBackgroundColor, bottomBackgroundColor, className }) => (
  <div style={{ backgroundColor: bottomBackgroundColor }} className={twMerge("h-10 w-full md:h-6", className)}>
    <svg
      viewBox="0 0 1425 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="h-full w-full"
      preserveAspectRatio="none">
      <path
        d="M709.986 6C1138.89 6 1349.58 40 1425 40V0H0V40C117.661 40 281.078 6 709.986 6Z"
        fill={topBackgroundColor}
      />
      <path
        d="M709.986 2C1138.89 2 1349.58 36 1425 36V0H0V36C117.661 36 281.078 2 709.986 2Z"
        fill={topBackgroundColor}
      />
    </svg>
  </div>
)

export default CurvyDividerReversed
