import React from "react"

const TriangleDivider = ({ topBackgroundColor, bottomBackgroundColor, middleBackgroundColor }) => (
  <div className="relative">
    <div
      className="absolute top-[-1px] h-6 w-full clip-path-triangle-bottom"
      style={{ backgroundColor: topBackgroundColor }}></div>
    <div
      className="absolute bottom-[-1px] h-6 w-full clip-path-triangle-top"
      style={{ backgroundColor: bottomBackgroundColor }}></div>
    <div className="h-16 w-full" style={{ backgroundColor: middleBackgroundColor }}></div>
  </div>
)

export default TriangleDivider
