import React from "react"
import { twMerge } from "tailwind-merge"

const CurvyDividerWaveReversed = ({ topBackgroundColor, bottomBackgroundColor, className }) => (
  <div style={{ backgroundColor: bottomBackgroundColor }} className={twMerge("h-10 w-full md:h-6", className)}>
    <svg
      viewBox="0 0 1425 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="h-full w-full"
      preserveAspectRatio="none">
      <path
        d="M968.94 6C1149.96 6 1349.58 30 1425 30V0H0V20C0 20 174.982 40 408.795 40C642.608 40 787.923 6 968.94 6Z"
        fill={topBackgroundColor}
      />
      <path
        d="M968.94 1C1149.96 1 1349.58 25 1425 25V0H0V15C0 15 174.982 35 408.795 35C642.608 35 787.923 1 968.94 1Z"
        fill={topBackgroundColor}
      />
    </svg>
  </div>
)

export default CurvyDividerWaveReversed
