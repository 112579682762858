import { WebAppBookNow } from "@heal-me/healme-widget"
import React from "react"
import { twMerge } from "tailwind-merge"

import { practiceDefaultServiceId } from "../../@core/practice/practice.utils"
import FacebookIcon from "../../components/icons/FacebookIcon"
import InstagramIcon from "../../components/icons/InstagramIcon"
import LinkedinIcon from "../../components/icons/LinkedinIcon"
import TikTokIcon from "../../components/icons/TikTokIcon"
import XTwitterIcon from "../../components/icons/XTwitterIcon"
import YoutubeIcon from "../../components/icons/YoutubeIcon"
import { scrollToId } from "../ProProfile/ScrollToSection"

import HealMeVerifiedBadge from "./HealMeVerifiedBadge"

const Footer = ({ practice, theme, backgroundColor, color, viewingInBuilder }) => {
  backgroundColor ||= theme.colorPrimary
  color ||= theme.colorTextDark

  return (
    <div className="py-16 md:px-5" style={{ backgroundColor, color }}>
      <div className="container mx-auto flex items-center justify-between lg:flex-col lg:gap-14">
        <div>
          <div className="text-xl font-bold">{practice.user.name}</div>
          <div className="max-w-xl text-lg">{practice.user.title}</div>
        </div>
        <div className="flex gap-8 lg:flex-col md:w-full">
          <button
            className="border-b border-transparent transition duration-150 ease-out hover:border-[var(--hover-color)] active:border-b-2"
            style={{ "--hover-color": theme.colorAccent }}
            onClick={(e) => scrollToId(e, "about")}>
            About
          </button>
          <button
            className="border-b border-transparent transition duration-150 ease-out hover:border-[var(--hover-color)] active:border-b-2"
            style={{ "--hover-color": theme.colorAccent }}
            onClick={(e) => scrollToId(e, "services")}>
            Services
          </button>
          <button
            className="border-b border-transparent transition duration-150 ease-out hover:border-[var(--hover-color)] active:border-b-2"
            style={{ "--hover-color": theme.colorAccent }}
            onClick={(e) => scrollToId(e, "reviews")}>
            Reviews
          </button>
          <button
            className="border-b border-transparent transition duration-150 ease-out hover:border-[var(--hover-color)] active:border-b-2"
            style={{ "--hover-color": theme.colorAccent }}
            onClick={(e) => scrollToId(e, "about")}>
            Credentials
          </button>

          <WebAppBookNow
            practiceId={practice.id}
            buttonCopy="Book Now"
            serviceId={practiceDefaultServiceId(practice)}
            buttonClasses={twMerge(
              "border bg-transparent px-8 py-2 transition duration-150 ease-out hover:border-[var(--hover-border-color)] hover:bg-[var(--hover-bg)] hover:text-[var(--hover-color)] md:w-full",
              practice.theme.buttonStyle === "rounded" ? "rounded-full" : "rounded",
              viewingInBuilder ? "pointer-events-none" : ""
            )}
            style={{
              borderColor: color,
              color,
              "--hover-border-color": theme.colorBgLight,
              "--hover-bg": theme.colorAccent,
              "--hover-color": theme.colorBgLight
            }}
          />
        </div>
      </div>
      <div className="my-24 flex justify-center gap-8">
        {practice.instagram && (
          <a href={practice.instagram} className="flex justify-center" target="_blank" rel="noreferrer">
            <InstagramIcon height={32} width={32} color={color} />
          </a>
        )}
        {practice.facebook && (
          <a href={practice.facebook} className="flex justify-center" target="_blank" rel="noreferrer">
            <FacebookIcon height={32} width={24} color={color} />
          </a>
        )}
        {practice.tiktok && (
          <a href={practice.tiktok} className="flex justify-center" target="_blank" rel="noreferrer">
            <TikTokIcon height={32} width={32} color={color} />
          </a>
        )}
        {practice.linkedin && (
          <a href={practice.linkedin} className="flex justify-center" target="_blank" rel="noreferrer">
            <LinkedinIcon height={32} width={22} color={color} />
          </a>
        )}
        {practice.twitter && (
          <a href={practice.twitter} className="flex justify-center" target="_blank" rel="noreferrer">
            <XTwitterIcon height={32} width={21} color={color} />
          </a>
        )}
        {practice.youtube && (
          <a href={practice.youtube} className="flex justify-center" target="_blank" rel="noreferrer">
            <YoutubeIcon height={32} width={28} color={color} />
          </a>
        )}
      </div>
      <div className="flex justify-center">
        <HealMeVerifiedBadge
          theme={theme}
          text="Powered by Heal.me"
          backgroundColor={backgroundColor}
          showBadgeIcon={false}
          color={color}
          link="https://heal.me"
        />
      </div>
    </div>
  )
}

export default Footer
