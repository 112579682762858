import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/outline"
import React, { useState } from "react"
import { twMerge } from "tailwind-merge"

const SeeMore = ({ onClick, className, style, buttonClassName, buttonStyle, buttonText = "See more" }) => {
  const [expanded, setExpanded] = useState(false)

  return (
    <div className={twMerge("mx-auto text-center", className)} style={style}>
      <button
        className={twMerge("transition duration-150 ease-out", buttonClassName)}
        style={buttonStyle}
        onClick={() => {
          setExpanded(!expanded)
          onClick()
        }}>
        {expanded ? "See less" : buttonText}
        <br />
        {expanded ? <ChevronUpIcon className="mx-auto h-6 w-6" /> : <ChevronDownIcon className="mx-auto h-6 w-6" />}
      </button>
    </div>
  )
}

export default SeeMore
