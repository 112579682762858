import capitalize from "lodash/capitalize"
import React from "react"
import { twMerge } from "tailwind-merge"

import { Button } from "../../components/shared/Buttons"

import { useWebsiteBuilder } from "./WebsiteBuilderContext"

const THEME_DESCRIPTIONS = {
  vitality: "Vibrant and energetic, with bright, uplifting colors.", // Theme 5
  harmony: "Balanced and flowing, perfect for mind-body-spirit integration.", // Theme 4
  serenity: "Calm and tranquil design with soft, soothing colors.", // Theme 3
  balance: "Clean and minimalist, emphasizing stability and clarity.", // Theme 2
  zen: "Simple and mindful, with open space and calm tones." // Theme 1
}

const ThemeTab = () => {
  const {
    setPractice,
    selectedTheme,
    setSelectedTheme,
    previewedTheme,
    setPreviewedTheme,
    defaultThemeStyles,
    updateTheme,
    setIsPreviewing,
    setSelectedStyleName
  } = useWebsiteBuilder()

  return (
    <div>
      <div className="flex flex-col">
        {Object.values(defaultThemeStyles).map((theme) => (
          <div
            key={theme.name}
            className="w-full py-2"
            onMouseEnter={() => {
              setPreviewedTheme(theme)
              setIsPreviewing(true)
              setPractice((prev) => ({
                ...prev,
                theme
              }))
            }}
            onMouseLeave={() => {
              setPreviewedTheme(selectedTheme)
              setIsPreviewing(false)
              setPractice((prev) => ({
                ...prev,
                theme: selectedTheme
              }))
            }}>
            <div
              onClick={() => {
                setSelectedTheme(theme)
                setPreviewedTheme(theme)
                setIsPreviewing(false)
                setPractice((prev) => ({
                  ...prev,
                  theme
                }))
                setSelectedStyleName(theme.name)
                updateTheme(theme)
              }}
              role="button"
              className={twMerge(
                "inline-flex h-[104px] w-full cursor-pointer items-center justify-start gap-4 rounded-xl border bg-white p-4 transition duration-300 ease-out md:h-fit",
                theme === selectedTheme ? "border-teal bg-gray-ultralight" : "border-gray-light",
                previewedTheme && previewedTheme !== selectedTheme && previewedTheme !== theme ? "opacity-25" : ""
              )}>
              <div className="flex h-16 w-16 items-center justify-center rounded-lg border border-gray shadow-inner">
                <img
                  className="h-[60px] w-[60px] rounded-lg"
                  src={`/images/theme_thumbnails/${theme.name.toLowerCase()}.png`}
                  alt={theme.name}
                />
              </div>
              <div className="inline-flex shrink grow basis-0 flex-col items-start justify-start gap-1">
                <div className="self-stretch font-bold leading-normal">{capitalize(theme.name)}</div>
                <div className="self-stretch text-sm font-normal leading-[21px] text-gray-dark">
                  {THEME_DESCRIPTIONS[theme.name]}
                </div>
              </div>
              {theme === selectedTheme ? (
                <div className="inline-flex flex-col items-start justify-start gap-2">
                  <div className="inline-flex items-center justify-center gap-2 self-stretch rounded bg-black px-4 font-bold leading-loose text-white">
                    Selected
                  </div>
                </div>
              ) : (
                <div className="inline-flex flex-col items-start justify-start gap-2">
                  <Button className="self-stretch">Select</Button>
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default ThemeTab
