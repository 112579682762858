import { WebAppBookNow } from "@heal-me/healme-widget"
import { ArrowRightIcon } from "@heroicons/react/24/outline"
import React from "react"

import { formatPrice, truncate } from "../../utils/utils"
import NextAvailableSlot from "../ProProfile/NextAvailableSlot"
import ScrollToSection from "../ProProfile/ScrollToSection"

const BookNowText = (
  <>
    <span className="underline">Book now</span>
    <span className="ml-2">
      <ArrowRightIcon className="h-4 w-4" />
    </span>
  </>
)

const MyServicesSection = ({ practice }) => {
  const services = practice.services.slice(0, 5)

  const formattedPrice = (amount, currency) => {
    if (amount === 0) {
      return "Free"
    }
    return `${formatPrice(amount)} ${currency == "CAD" ? " CAD" : ""}`
  }

  const largeImageUrl = (service) => {
    const handle = service.photoHandles?.large?.webp || service.photoHandles?.original?.any
    return handle ? `https://cdn.filestackcontent.com/${handle}` : null
  }

  return (
    <ScrollToSection id="services" className="mb-0 border-none pb-0">
      <div className="mx-auto max-w-4xl px-6 py-8">
        <h2 className="mb-8 text-center font-profilePage text-2xl">MY SERVICES</h2>
        {services.map((service, index) => (
          <div key={index} className="mb-12 flex flex-col items-start rounded-lg bg-white py-6 min_md:flex-row">
            <div className="w-full min_md:w-3/4">
              <h3 className="mb-2 font-profilePage text-3xl font-semibold">{service.name}</h3>
              <div className="mb-2 text-lg">
                <span
                  className="wysiwyg-content"
                  dangerouslySetInnerHTML={{
                    __html: truncate(service.description, 180).replace(/<\/?(p|h2|h3|h4|ul|ol|li)[^>]*>/g, " ")
                  }}
                />
                {service.description.length > 180 && (
                  <WebAppBookNow
                    practiceId={practice.id}
                    serviceId={service.id}
                    buttonCopy="See more"
                    buttonClasses="font-bold underline"
                    openServiceDetail={true}
                  />
                )}
              </div>
              <p className="mb-2 mt-6 text-lg">
                <span className="font-bold">{formattedPrice(service.amountCents, practice.defaultCurrency)} ·</span>{" "}
                <span>{service.timeLength} minutes</span>
                {service.package && <span> · {service.numberOfSessions} sessions</span>}
              </p>
              <div className="flex items-baseline gap-12 md:flex-col md:gap-4">
                <div className="mt-4 inline-block font-bold text-pink">
                  <WebAppBookNow
                    practiceId={practice.id}
                    serviceId={service.id}
                    buttonCopy={BookNowText}
                    buttonClasses="flex items-center"
                  />
                </div>
                {!service.package && <NextAvailableSlot service={service} />}
              </div>
            </div>
            <div className="mt-6 flex w-full justify-center min_md:mt-0 min_md:w-1/4">
              {largeImageUrl(service) && (
                <img src={largeImageUrl(service)} alt={service.name} className="h-[200px] w-[200px] rounded-lg" />
              )}
            </div>
          </div>
        ))}
        <WebAppBookNow
          practiceId={practice.id}
          buttonCopy="See all services"
          buttonClasses="border-pink border rounded py-1 px-4 font-bold bg-white text-pink"
        />
      </div>
    </ScrollToSection>
  )
}

export default MyServicesSection
