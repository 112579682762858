import React from "react"

const InstagramIcon = ({ height = 32, width = 32, color = "#55778A" }) => (
  <svg width={width} height={height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.9984 8.13079C18.5616 8.13079 18.8656 8.14039 19.8784 8.18679C22.48 8.30519 23.6952 9.53959 23.8136 12.122C23.86 13.134 23.8688 13.438 23.8688 16.0012C23.8688 18.5652 23.8592 18.8684 23.8136 19.8804C23.6944 22.4604 22.4824 23.6972 19.8784 23.8156C18.8656 23.862 18.5632 23.8716 15.9984 23.8716C13.4352 23.8716 13.1312 23.862 12.1192 23.8156C9.51124 23.6964 8.30244 22.4564 8.18404 19.8796C8.13764 18.8676 8.12804 18.5644 8.12804 16.0004C8.12804 13.4372 8.13844 13.134 8.18404 12.1212C8.30324 9.53959 9.51524 8.30439 12.1192 8.18599C13.132 8.14039 13.4352 8.13079 15.9984 8.13079ZM15.9984 6.40039C13.3912 6.40039 13.0648 6.41159 12.0408 6.45799C8.55444 6.61799 6.61684 8.55239 6.45684 12.042C6.40964 13.0668 6.39844 13.3932 6.39844 16.0004C6.39844 18.6076 6.40964 18.9348 6.45604 19.9588C6.61604 23.4452 8.55044 25.3828 12.04 25.5428C13.0648 25.5892 13.3912 25.6004 15.9984 25.6004C18.6056 25.6004 18.9328 25.5892 19.9568 25.5428C23.44 25.3828 25.3824 23.4484 25.54 19.9588C25.5872 18.9348 25.5984 18.6076 25.5984 16.0004C25.5984 13.3932 25.5872 13.0668 25.5408 12.0428C25.384 8.55959 23.4472 6.61879 19.9576 6.45879C18.9328 6.41159 18.6056 6.40039 15.9984 6.40039ZM15.9984 11.0708C13.276 11.0708 11.0688 13.278 11.0688 16.0004C11.0688 18.7228 13.276 20.9308 15.9984 20.9308C18.7208 20.9308 20.928 18.7236 20.928 16.0004C20.928 13.278 18.7208 11.0708 15.9984 11.0708ZM15.9984 19.2004C14.2312 19.2004 12.7984 17.7684 12.7984 16.0004C12.7984 14.2332 14.2312 12.8004 15.9984 12.8004C17.7656 12.8004 19.1984 14.2332 19.1984 16.0004C19.1984 17.7684 17.7656 19.2004 15.9984 19.2004ZM21.1232 9.72439C20.4864 9.72439 19.9704 10.2404 19.9704 10.8764C19.9704 11.5124 20.4864 12.0284 21.1232 12.0284C21.7592 12.0284 22.2744 11.5124 22.2744 10.8764C22.2744 10.2404 21.7592 9.72439 21.1232 9.72439Z"
      fill={color}
    />
  </svg>
)

export default InstagramIcon
