import React from "react"
import { twMerge } from "tailwind-merge"

const WaveDivider = ({ topBackgroundColor, bottomBackgroundColor, className }) => (
  <div style={{ backgroundColor: topBackgroundColor }} className={twMerge("h-24 w-full md:h-16", className)}>
    <svg
      viewBox="0 0 1425 96"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="h-full w-full"
      preserveAspectRatio="none">
      <path
        d="M968.94 81.6C1149.96 81.6 1349.58 24 1425 24V96H0V48C0 48 174.982 0 408.795 0C642.608 0 787.923 81.6 968.94 81.6Z"
        fill={bottomBackgroundColor}
      />
    </svg>
  </div>
)

export default WaveDivider
