import { WebAppBookNow } from "@heal-me/healme-widget"
import React from "react"

import Typography from "../../components/shared/Typography"

export default function CTASection({ practice }) {
  const proIsVirtualOnly = practice.locations.every((location) => location.kind === "virtual")
  const hasFreeConsultation = practice.services.some((service) => service.amountCents === 0)

  const title = hasFreeConsultation ? "Schedule a free discovery call now." : "Schedule an appointment now."

  const subtitle = `I'm based in ${practice.city}, ${practice.stateAbbreviation}, and offer ${
    proIsVirtualOnly ? "virtual" : "in-person and video"
  } sessions.`

  const ctaText = hasFreeConsultation ? "Book your free discovery call" : "Book your appointment now"

  let imgUrl =
    practice.galleryImages.length > 1 && practice.galleryImages[1]?.filestackPhoto?.large?.webp
      ? `https://cdn.filestackcontent.com/${practice.galleryImages[1]?.filestackPhoto?.large?.webp}`
      : "/images/homepage/hero_images/image-5.webp"

  return (
    <section className="min-h-[50vh]">
      <div
        className="min-h-[50vh] bg-cover bg-center bg-no-repeat bg-blend-multiply"
        style={{ backgroundImage: `url(${imgUrl})` }}>
        <div className="flex h-full min-h-[50vh] w-full items-center bg-purple bg-opacity-[0.64] backdrop-blur-md">
          <div className="mx-auto flex h-full max-w-splash-container items-end justify-center hplg:px-8 sm:px-6">
            <div className="flex h-full max-w-3xl flex-col items-center gap-4 py-24 text-center sm:pb-16">
              <Typography variant="h2" className="font-profilePage leading-tight text-white">
                {title}
              </Typography>
              <Typography variant="h4" as="p" className="mb-4 font-normal leading-normal text-white">
                {subtitle}
              </Typography>
              <WebAppBookNow
                practiceId={practice.id}
                buttonCopy={ctaText}
                buttonClasses="md:w-full rounded-lg border-pink bg-pink font-bold px-8 py-4 text-white"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
