import React from "react"

import Navigation from "../ProProfileShared/Navigation"

const ThemeFiveNavigation = ({ practice, theme, viewingInBuilder }) => (
  <Navigation
    practice={practice}
    theme={theme}
    viewingInBuilder={viewingInBuilder}
    className="bg-transparent"
    ClipPath={() => (
      <div className="absolute top-0 h-16 w-full bg-transparent">
        <div
          className="absolute inset-0 clip-path-diagonal-line-reversed"
          style={{ backgroundColor: practice.theme.colorPrimary }}
        />
      </div>
    )}
    ClipPathTwo={() => (
      <div className="absolute bottom-0 h-16 w-full bg-transparent">
        <div
          className="absolute inset-0 bottom-[-1px] clip-path-diagonal-line"
          style={{ backgroundColor: practice.theme.colorSecondary }}
        />
      </div>
    )}
  />
)

export default ThemeFiveNavigation
