import React from "react"

import YoutubeIcon from "../../components/icons/YoutubeIcon"
import { scrollToAbout, scrollToCredentials, scrollToReviews, scrollToServices } from "../ProProfileV2"

const ProProfileFooter = ({ practice }) => (
  <footer className="bg-pink/10 px-6 py-20">
    <div className="mx-auto flex max-w-6xl flex-col justify-between min_md:flex-row">
      <div className="text-center min_md:text-left">
        <h1 className="mb-2 font-profilePage text-3xl font-bold">{practice.user.name}</h1>
        <p className="text-lg">
          {practice.city}, {practice.stateAbbreviation}
        </p>
      </div>
      <div className="flex flex-1 flex-col items-center justify-between min_md:items-end">
        <div className="mt-4 flex justify-between gap-4 md:flex-col min_md:mt-0">
          <button onClick={scrollToServices} className="text-center text-lg font-semibold">
            Services
          </button>
          <button onClick={scrollToAbout} className="text-center text-lg font-semibold">
            About
          </button>
          <button onClick={scrollToReviews} className="text-center text-lg font-semibold">
            Reviews
          </button>
          <button onClick={scrollToCredentials} className="text-center text-lg font-semibold">
            Credentials
          </button>
          {practice.instagram && (
            <a href={practice.instagram} className="flex justify-center">
              <img src="/images/icons/instagram.svg" alt="Instagram" />
            </a>
          )}
          {practice.facebook && (
            <a href={practice.facebook} className="flex justify-center">
              <img src="/images/icons/facebook.svg" alt="Facebook" />
            </a>
          )}
          {practice.tiktok && (
            <a href={practice.tiktok} className="flex justify-center">
              <img src="/images/icons/tiktok.svg" alt="TikTok" />
            </a>
          )}
          {practice.linkedin && (
            <a href={practice.linkedin} className="flex justify-center">
              <img src="/images/icons/linkedin.svg" alt="LinkedIn" />
            </a>
          )}
          {practice.twitter && (
            <a href={practice.twitter} className="flex justify-center">
              <img src="/images/icons/twitter.svg" alt="Twitter" />
            </a>
          )}
          {practice.youtube && (
            <a href={practice.youtube} className="flex justify-center">
              <YoutubeIcon />
            </a>
          )}
        </div>
        <div className="mt-4 flex items-end rounded-md border bg-white px-3 py-2">
          <div>
            Powered by <span className="font-bold">Heal.me</span>
          </div>
        </div>
      </div>
    </div>
  </footer>
)

export default ProProfileFooter
