import React from "react"
import { twMerge } from "tailwind-merge"

const SectionTitle = ({ className, theme, color, children }) => {
  const fontFamily = theme.fontHeading
  const fontWeight = theme.fontHeadingWeight

  return (
    <h2
      className={twMerge("text-[40px] leading-[48px] lg:text-[27px] lg:leading-loose", className)}
      style={{ color, fontFamily, fontWeight }}>
      {children}
    </h2>
  )
}

export default SectionTitle
