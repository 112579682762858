import React from "react"
import { twMerge } from "tailwind-merge"

const CurvyDividerWave = ({ topBackgroundColor, bottomBackgroundColor, className }) => (
  <div style={{ backgroundColor: topBackgroundColor }} className={twMerge("h-10 w-full md:h-6", className)}>
    <svg
      viewBox="0 0 1425 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="h-full w-full"
      preserveAspectRatio="none">
      <path
        d="M968.94 34C1149.96 34 1349.58 10 1425 10V40H0V20C0 20 174.982 0 408.795 0C642.608 0 787.923 34 968.94 34Z"
        fill={bottomBackgroundColor}
      />
      <path
        d="M968.94 39C1149.96 39 1349.58 15 1425 15V40H0V25C0 25 174.982 5 408.795 5C642.608 5 787.923 39 968.94 39Z"
        fill={bottomBackgroundColor}
      />
    </svg>
  </div>
)

export default CurvyDividerWave
