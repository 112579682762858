import React, { useState, useRef } from "react"

import { useContainerDimensions } from "../../hooks/useContainerDimensions"
import useWindowSize from "../../hooks/useWindowSize"

import { AnimatedModal } from "./Modal"

const HoverPopover = ({ children, popoverContent, credentialKind }) => {
  const ref = useRef(null)
  const { width } = useWindowSize()
  const { height } = useContainerDimensions(ref)
  const [isPopoverVisible, setIsPopoverVisible] = useState(false)

  return (
    <div className="relative flex flex-col items-start">
      <div onMouseEnter={() => setIsPopoverVisible(true)} onMouseLeave={() => setIsPopoverVisible(false)} ref={ref}>
        {children}
      </div>
      {isPopoverVisible && (
        <div
          className="absolute z-9998 rounded-xl border border-gray bg-white p-8 shadow-lg sm:hidden"
          onMouseEnter={() => setIsPopoverVisible(true)}
          onMouseLeave={() => setIsPopoverVisible(false)}
          style={{ marginTop: height + 2 }}>
          {popoverContent}
        </div>
      )}
      {width > 0 && width < 640 && (
        <AnimatedModal
          visible={isPopoverVisible}
          hideModal={() => setIsPopoverVisible(false)}
          header={`Verified ${credentialKind} details`}
          showFooter={true}
          hideSaveButton={true}>
          {popoverContent}
        </AnimatedModal>
      )}
    </div>
  )
}

export default HoverPopover
