import { WebAppBookNow } from "@heal-me/healme-widget"
import { ChevronDownIcon } from "@heroicons/react/24/outline"
import dayjs from "dayjs"
import React, { useEffect, useRef, useState } from "react"
import { twMerge } from "tailwind-merge"
import { useQuery } from "urql"

import { BASE_INPUT_CLASSNAMES, Label } from "../../components/shared/Inputs"
import { Box } from "../../components/shared/Layout"
import LoadingSpinner from "../../components/shared/LoadingSpinner"
import { RadioWithLabel } from "../../components/shared/RadioButtons"
import Typography from "../../components/shared/Typography"
import { formatPrice } from "../../utils/utils"

const AVAILABILITY_QUERY = `
  query bookingAvailability($serviceId: ID!, $locationId: ID!) {
    bookingAvailability(serviceId: $serviceId, locationId: $locationId) {
      timeZone
      availableSlots { date datetimes }
    }
  }
`

const BookingWidget = ({ practice, selectedService }) => {
  const [selectedLocation, setSelectedLocation] = useState(selectedService.locations[0])
  const [top, setTop] = useState(0)
  const [position, setPosition] = useState("static")
  const [offsetLeft, setOffsetLeft] = useState(0)
  const [isScrolledIntoFooter, setIsScrolledIntoFooter] = useState(false)
  const [bottom, setBottom] = useState(0)
  const [hiddenOnMobile, setHiddenOnMobile] = useState(true)
  const ref = useRef(null)
  const practitioner = practice.user
  const highlyRated =
    practice.reviews.length >= 3 && practice.reviews.filter((r) => r.rating === 5).length === practice.reviews.length

  const [{ data, fetching, error }, refetchQuery] = useQuery({
    query: AVAILABILITY_QUERY,
    variables: { serviceId: selectedService.id, locationId: selectedLocation.id }
  })

  if (error) console.error(error)

  const availableSlots = data?.bookingAvailability?.availableSlots?.filter((day) => day.datetimes.length > 0)?.[0]
  const timeZone =
    selectedLocation.kind === "virtual"
      ? Intl.DateTimeFormat().resolvedOptions().timeZone
      : selectedLocation?.formattedTimeZone
  const timeZoneAbbr =
    selectedLocation.kind === "virtual"
      ? new Date().toLocaleTimeString("en-us", { timeZoneName: "short" }).split(" ")[2]
      : selectedLocation?.timeZoneAbbr
  const firstSlot =
    availableSlots && (dayjs(availableSlots.datetimes[0]) || dayjs(availableSlots.datetimes[0]).tz(timeZone))
  const momentDate = availableSlots && dayjs(availableSlots.date)
  const dateFormat = "dddd, MMMM Do"
  const isToday = momentDate && dayjs().format(dateFormat) === momentDate.format(dateFormat)
  const isTomorrow = momentDate && dayjs().add(1, "days").format(dateFormat) === momentDate.format(dateFormat)

  const handleResize = () => {
    if (ref.current) {
      setOffsetLeft(ref.current.offsetLeft)
    }
  }

  const handleScroll = () => {
    const scrollHeight = document.documentElement.scrollHeight
    const navHeight = document.querySelector("nav").offsetHeight
    const footerHeight = document.querySelector("footer").offsetHeight
    const footerOffsetTop = document.querySelector("footer").offsetTop
    const widgetHeight = document.getElementById("booking-widget-desktop").offsetHeight
    const distanceFromTop = navHeight + 32 // 32 is the padding of the widget
    const footerTop = scrollHeight - footerHeight - widgetHeight - distanceFromTop
    const bottom = window.innerHeight - (footerOffsetTop - window.scrollY)
    setBottom(bottom)
    setIsScrolledIntoFooter(footerTop < window.scrollY)
    setHiddenOnMobile(false)

    const offsetTop = ref.current.offsetTop
    if (window.scrollY > offsetTop - distanceFromTop) {
      setPosition("fixed")
      setTop(distanceFromTop)
    } else {
      setPosition("static")
    }
  }

  useEffect(() => {
    window.addEventListener("resize", handleResize)
    window.addEventListener("scroll", handleScroll)
    const timeout = setTimeout(() => {
      setHiddenOnMobile(false)
    }, 3000)

    return () => {
      window.removeEventListener("resize", handleResize)
      window.removeEventListener("scroll", handleScroll)
      clearTimeout(timeout)
    }
  }, [ref])

  const widgetStyle = { position }
  if (isScrolledIntoFooter) {
    widgetStyle.bottom = bottom
  } else {
    widgetStyle.top = top
    widgetStyle.offsetLeft = offsetLeft
  }

  return (
    <>
      <div className="w-[400px] md:hidden" ref={ref}>
        <Box
          id="booking-widget-desktop"
          as="section"
          className="z-50 w-[400px] shadow-lg"
          style={ref.current ? widgetStyle : null}>
          <WebAppBookNow
            practiceId={practice.id}
            buttonClasses={twMerge(BASE_INPUT_CLASSNAMES, "border-gray-light px-5 py-4")}
            serviceId={selectedService.id}
            locationId={selectedLocation.id}
            buttonCopy={
              <div className="flex items-center justify-between gap-2">
                <div className="text-left">
                  <div className="font-bold">
                    {selectedService.name.length > 67
                      ? `${selectedService.name.substring(0, 67)}...`
                      : selectedService.name}
                  </div>
                  <div className="text-sm">
                    <span className="text-sm font-bold">
                      {selectedService.amountCents === 0
                        ? "Free"
                        : `${formatPrice(selectedService.amountCents)}${
                            practice.defaultCurrency !== "USD" ? practice.defaultCurrency : ""
                          }`}
                    </span>{" "}
                    <span className="text-gray">&bull;</span> {selectedService.timeLength} min
                  </div>
                </div>
                <div>
                  <ChevronDownIcon className="h-5 w-5 text-gray-dark" aria-hidden="true" />
                </div>
              </div>
            }
          />
          {selectedService.locations.length > 1 && (
            <div className="mt-4 flex flex-col">
              <Label>Location</Label>
              {selectedService.locations.map((location) => (
                <RadioWithLabel
                  id={`location-radio-${location.id}}`}
                  className="my-1"
                  key={location.id}
                  value={`${location}`}
                  label={location.displayAddress}
                  checked={location === selectedLocation}
                  onChange={() => {
                    setSelectedLocation(location)
                    refetchQuery({ requestPolicy: "network-only" })
                  }}
                />
              ))}
            </div>
          )}
          {fetching ? (
            <LoadingSpinner />
          ) : availableSlots ? (
            <div>
              <div className="mt-4 text-sm font-bold">
                {isToday
                  ? `Today, ${firstSlot.format("MMMM D")} (${timeZoneAbbr})`
                  : isTomorrow
                  ? `Tomorrow, ${firstSlot.format("MMMM D")} (${timeZoneAbbr})`
                  : `${firstSlot.format(dateFormat)} (${timeZoneAbbr})`}
              </div>
              <div className="my-2 grid grid-cols-3 gap-3">
                {availableSlots.datetimes.slice(0, 6).map((slot) => (
                  <div key={slot}>
                    <WebAppBookNow
                      practiceId={practice.id}
                      serviceId={selectedService.id}
                      locationId={selectedLocation.id}
                      dateTime={slot}
                      buttonCopy={dayjs(slot).tz(timeZone).format("h:mma")}
                      buttonClasses="h-10 rounded border px-4 font-bold focus:outline-none focus:ring-2 focus:ring-blue border-gray w-full bg-white hover:shadow active:bg-gray-light"
                    />
                  </div>
                ))}
              </div>
              <WebAppBookNow
                practiceId={practice.id}
                locationId={selectedLocation.id}
                serviceId={selectedService.id}
                buttonCopy="Show more times"
                buttonClasses="text-gray-dark underline font-bold"
              />
            </div>
          ) : (
            <div className="mt-4">No availability in the next 45 days. Please check back later.</div>
          )}
          <WebAppBookNow
            practiceId={practice.id}
            locationId={selectedLocation.id}
            serviceId={selectedService.id}
            buttonCopy="Continue booking"
            buttonClasses="h-12 w-full rounded border px-4 font-bold focus:outline-none focus:ring-2 focus:ring-blue border-teal bg-teal text-white hover:shadow active:bg-teal-dark mt-6"
          />
          {highlyRated && (
            <div className="mt-6 flex items-center gap-2">
              <div className="flex h-12 w-12 items-center justify-center rounded-2xl bg-gray-ultralight">
                <img className="h-10 w-10 rounded-2xl" src="/images/icons/gem-red.png" alt="Highly rated" />
              </div>
              <div>
                <div className="font-bold">Highly rated</div>
                <Typography variant="smSubtitle">{practitioner.firstName} has all 5-star reviews</Typography>
              </div>
            </div>
          )}
          {practice.loyalClients && (
            <div className={`flex items-center gap-2 ${highlyRated ? "mt-4" : "mt-6"}`}>
              <div className="flex h-12 w-12 items-center justify-center rounded-2xl bg-gray-ultralight">
                <img className="h-10 w-10 rounded-2xl" src="/images/icons/gem-blue.png" alt="Highly rated" />
              </div>
              <div>
                <div className="font-bold">Loyal clients</div>
                <Typography variant="smSubtitle">{practitioner.firstName}&apos;s clients rebook often</Typography>
              </div>
            </div>
          )}
        </Box>
      </div>
      <div
        className={`fixed bottom-0 z-50 -ml-6 hidden w-full border-t border-gray-light bg-white px-6 py-5 duration-500 ease-in-out md:block ${
          hiddenOnMobile ? "translate-y-[128px]" : "translate-y-0"
        }`}>
        <WebAppBookNow
          practiceId={practice.id}
          buttonCopy="View availability"
          buttonClasses="h-10 w-full rounded border px-4 font-bold focus:outline-none focus:ring-2 focus:ring-blue border-teal bg-teal text-white hover:shadow active:bg-teal-dark"
        />
        <div className="mt-2 flex items-center justify-between">
          {highlyRated && (
            <div className="flex items-center gap-2">
              <div className="flex h-10 w-10 items-center justify-center rounded-2xl bg-gray-ultralight">
                <img className="h-10 w-10 rounded-2xl" src="/images/icons/gem-red.png" alt="Highly rated" />
              </div>
              <div className="text-sm font-bold">Highly rated</div>
            </div>
          )}
          {practice.loyalClients && (
            <div className="flex items-center gap-2">
              <div className="flex h-10 w-10 items-center justify-center rounded-2xl bg-gray-ultralight">
                <img className="h-10 w-10 rounded-2xl" src="/images/icons/gem-blue.png" alt="Highly rated" />
              </div>
              <div className="text-sm font-bold">Loyal clients</div>
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default BookingWidget
