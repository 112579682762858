import React, { useEffect, useState } from "react"
import { twMerge } from "tailwind-merge"

const BackgroundImage = ({
  id,
  overlayColor,
  className,
  imageUrl,
  ClipPath,
  ClipPathTwo,
  parallax = false,
  maxOffset = "420px",
  children
}) => {
  const [offsetY, setOffsetY] = useState(0)

  const handleScroll = () => {
    setOffsetY(Math.min(window.pageYOffset, maxOffset))
  }

  useEffect(() => {
    window.addEventListener("scroll", handleScroll)

    return () => window.removeEventListener("scroll", handleScroll)
  }, [])

  const imageStyle = {
    backgroundImage: `url(${imageUrl.replace(/\+/g, "%20").replace(/\(/g, "%28").replace(/\)/g, "%29")})`
  }

  if (parallax) {
    imageStyle.backgroundAttachment = "fixed"
    imageStyle.backgroundPosition = `center ${offsetY * 0.5}px`
  }

  return (
    <div
      id={id}
      className={twMerge(
        "relative flex min-h-[700px] items-center justify-center bg-cover bg-no-repeat bg-blend-multiply",
        className
      )}
      style={imageStyle}>
      <div className="container z-10 mx-auto">{children}</div>
      {overlayColor && <div className="absolute inset-0 h-full w-full" style={{ backgroundColor: overlayColor }} />}
      {ClipPath && <ClipPath />}
      {ClipPathTwo && <ClipPathTwo />}
    </div>
  )
}

export default BackgroundImage
