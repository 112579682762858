export const generateCustomSource = (sourceUrlsArray) => ({
  label: "Heal.me Images",
  name: "CUSTOM_SOURCE_KEY_NAME",
  icon: '<svg height="36" viewBox="0 0 36 36" width="36" xmlns="http://www.w3.org/2000/svg"><g fill="none" fill-rule="evenodd"><circle cx="18" cy="18" fill="#eee" r="18"/><path d="m9.9 18c0-1.71 1.39-3.1 3.1-3.1h4v-1.9h-4c-2.76 0-5 2.24-5 5s2.24 5 5 5h4v-1.9h-4c-1.71 0-3.1-1.39-3.1-3.1zm4.1 1h8v-2h-8zm9-6h-4v1.9h4c1.71 0 3.1 1.39 3.1 3.1s-1.39 3.1-3.1 3.1h-4v1.9h4c2.76 0 5-2.24 5-5s-2.24-5-5-5z" fill="#000" fill-opacity=".7"/></g></svg>',
  mounted: (element, actions) => {
    const list = document.createElement("ul")
    list.setAttribute("class", "fsp-picker--custom-source")

    const handleImageClick = (e) => {
      e.stopPropagation()

      const element = e.currentTarget
      const idx = parseInt(element.dataset.idx)
      const file = sourceUrlsArray[idx]

      if (file) {
        element.classList.add("file-selected")
      } else {
        return
      }

      actions.fetchAndAddUrl(file.url).then(() => {
        actions.showSummaryView()
      })

      return false
    }

    // Create the list of files
    sourceUrlsArray.forEach((element, idx) => {
      const li = document.createElement("li")
      const span = document.createElement("span")

      li.dataset.idx = idx
      li.setAttribute("id", `file-${idx}`)

      const thumb = document.createElement("img")
      thumb.setAttribute("src", element.url)
      thumb.width = "20"
      thumb.height = "20"

      li.appendChild(thumb)
      span.innerHTML = `${element.name}`
      li.appendChild(span)

      li.addEventListener("click", handleImageClick)
      list.appendChild(li)
    })

    // Add header to change view type
    const divHeader = document.createElement("div")
    divHeader.setAttribute("class", "fsp-picker--custom-source-header")

    let buttonViewType = document.createElement("span")
    buttonViewType.classList.add("view-type")

    divHeader.appendChild(buttonViewType)

    const handleViewTypeClick = (e) => {
      e.stopPropagation()
      if (element.classList.contains("grid")) {
        element.classList.remove("grid")
      } else {
        element.classList.add("grid")
      }
    }

    buttonViewType.addEventListener("click", handleViewTypeClick)
    buttonViewType.click() // Default to grid view
    const content = document.querySelector(".fsp-content")
    content.setAttribute("style", "overflow: auto;")

    // Add the header and list to the element
    element.appendChild(divHeader)
    element.appendChild(list)

    // Automatically select the first image and go to cropping area
    if (sourceUrlsArray.length > 0) {
      const firstFile = sourceUrlsArray[0]
      actions.fetchAndAddUrl(firstFile.url).then(() => {
        // will automatically go to crop view if enabled
      })
    }
  },
  unmounted: () => {}
})

// Switch to custom source by clicking on the custom source icon
export const switchToCustomSourceOnOpen = () => {
  const startTime = Date.now()

  const switchToCustomSource = () => {
    const customSource = document.querySelector(".fsp-custom-icon")

    if (customSource) {
      customSource.click()
    } else if (Date.now() - startTime < 10000) {
      setTimeout(switchToCustomSource, 100)
    }
  }

  switchToCustomSource()
}
