import { practiceDefaultServiceId, practiceCtaText } from "@core/practice/practice.utils"
import { WebAppBookNow } from "@heal-me/healme-widget"
import React from "react"
import { twMerge } from "tailwind-merge"

import AnimatedSection from "../ProProfileShared/AnimatedSection"
import { DEFAULT_HIGHLIGHT_CLASSES } from "../WebsiteBuilder/WebsiteBuilderPage"

const HeroSection = ({ practice, theme, highlight, Navigation, viewingInBuilder }) => {
  const highlightClasses = "rounded border-2 border-teal shadow-lg w-fit"
  const defaultServiceId = practiceDefaultServiceId(practice)
  const ctaText = practiceCtaText(practice)

  return (
    <div className="relative" style={{ backgroundColor: theme.colorBgLight }} id="hero-section">
      <AnimatedSection className="container mx-auto flex min-h-[700px] items-center justify-between gap-10 pb-14 pt-[102px] md:flex-col md:px-5 md:text-center">
        <div className="min_md:w-1/2">
          <h1
            className={twMerge(
              "text-[56px] leading-[72px] lg:text-4xl lg:leading-[48px]",
              highlight === "websiteHeadline" ? highlightClasses : ""
            )}
            style={{ fontFamily: theme.fontHeading, fontWeight: theme.fontHeadingWeight, color: theme.colorTextDark }}>
            {practice.websiteHeadline}
          </h1>
          <p
            className={twMerge(
              "mt-4 text-xl leading-8",
              highlight === "subHeadline" ? DEFAULT_HIGHLIGHT_CLASSES : "",
              highlight === "subHeadline" ? "mx-auto w-fit px-8 py-2" : ""
            )}>
            {practice.subHeadline}
          </p>
          <div className="mt-8">
            <WebAppBookNow
              practiceId={practice.id}
              serviceId={defaultServiceId}
              buttonCopy={ctaText}
              buttonClasses={twMerge(
                "border border-[var(--bg-color)] px-10 py-3 transition duration-150 ease-out hover:border-[var(--hover-color)] md:w-full",
                highlight === "ctaButtonText" ? DEFAULT_HIGHLIGHT_CLASSES : "",
                theme.buttonStyle === "rounded" ? "rounded-full" : "rounded",
                viewingInBuilder ? "pointer-events-none" : ""
              )}
              style={{
                color: theme.colorTextLight,
                backgroundColor: theme.colorAccent,
                "--bg-color": theme.colorAccent,
                "--hover-color": theme.colorTextDark
              }}
            />
          </div>
        </div>
        <div className="min_md:w-1/2">
          <img className="aspect-square w-full object-cover" src={practice.headerHeroImageUrl} alt="Hero image" />
        </div>
      </AnimatedSection>
      <Navigation />
    </div>
  )
}

export default HeroSection
