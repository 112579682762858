import { VideoCameraIcon } from "@heroicons/react/24/outline"
import { CheckBadgeIcon } from "@heroicons/react/24/solid"
import React from "react"

import ReviewStars from "../../components/shared/ReviewStars"

import { scrollToId } from "./ScrollToSection"

const VideoIcon = () => (
  <div className="flex items-center justify-center rounded-full bg-gray-light p-1">
    <VideoCameraIcon className="h-4 w-4 stroke-2 text-gray-dark" />
  </div>
)

const DesktopHeaderSection = ({ practice }) => {
  const practitioner = practice.user
  const reviews = practice.reviews
  const roundedAverageRating = Math.round(practice.averageRating)
  const proCredential = practice.proCredentials[0]
  const proIsVirtualOnly = practice.locations.every((location) => location.kind === "virtual")

  const scrollToCredentials = (e) => scrollToId(e, "credentials")
  const scrollToReviews = (e) => scrollToId(e, "reviews")

  return (
    <section className="w-full bg-gray-ultralight py-8 md:hidden">
      <div className="mx-auto flex w-[368px] flex-col items-center gap-1 text-center md:hidden">
        <div className="flex-none">
          <img
            src={practitioner.profilePhotoUrl}
            alt={practitioner.name}
            className="h-[320px] w-[320px] rounded-lg sm:h-[80px] sm:w-[80px]"
          />
        </div>
        <h1 className="mt-3 flex w-full items-center justify-center gap-2">
          <div className="text-[18px] font-bold">{practitioner.name}</div>
          <img className="h-6 w-6 text-teal" src="/images/icons/verified.svg" alt="Verified" />
        </h1>
        <div>{practitioner.title}</div>
        <div className="mt-3 flex items-center gap-2">
          <ReviewStars rating={roundedAverageRating} />
          <div>
            <button className="font-bold text-teal underline" onClick={scrollToReviews}>
              {reviews.length} reviews
            </button>
          </div>
        </div>
        <div className="flex items-center gap-2">
          <span className="sm:hidden">
            {practice.city}, {practice.stateAbbreviation}
          </span>
          {proIsVirtualOnly && <VideoIcon />}
        </div>
        {proCredential && (
          <div className="mt-1 flex items-center gap-2">
            <div className="flex w-fit items-center gap-1 rounded-lg border border-gray bg-white px-2 py-1">
              <CheckBadgeIcon className="h-5 w-5 flex-none text-gray-dark opacity-[0.64]" />
              <div className="text-sm">{proCredential.credential.name}</div>
            </div>
            {practice.proCredentials.length > 1 && (
              <button className="flex-none text-sm" onClick={scrollToCredentials}>
                +{practice.proCredentials.length - 1} more
              </button>
            )}
          </div>
        )}
      </div>
    </section>
  )
}

export default DesktopHeaderSection
