import { CheckBadgeIcon } from "@heroicons/react/24/outline"
import React from "react"

const HealMeVerifiedBadge = ({
  theme,
  text = "Reviews are Heal.me verified",
  backgroundColor,
  color,
  showBadgeIcon = true,
  link
}) => {
  backgroundColor ||= theme.colorGreyLightest
  color ||= theme.colorTextDark

  const badgeContent = (
    <div
      className="-ml-3 inline-flex w-fit items-center justify-center gap-2 rounded-full px-3 pl-6"
      style={{ backgroundColor, color }}>
      <div className="text-xs font-bold uppercase leading-[18px] tracking-wide">{text}</div>
      {showBadgeIcon && <CheckBadgeIcon className="h-6 w-6" />}
    </div>
  )

  return (
    <div className="flex h-10 flex-row-reverse items-center">
      {link ? (
        <a href={link} target="_blank" rel="noreferrer">
          {badgeContent}
        </a>
      ) : (
        badgeContent
      )}
      <div className="z-10 inline-flex h-10 w-10 items-center justify-center">
        <a href="https://heal.me" target="_blank" rel="noreferrer">
          <img src="/images/icons/heal-me-logo-color.png" alt="Heal.me" className="h-10 w-10 rounded" />
        </a>
      </div>
    </div>
  )
}

export default HealMeVerifiedBadge
