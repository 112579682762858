import React from "react"
import { twMerge } from "tailwind-merge"

const DiagonalDivider = ({ topBackgroundColor, bottomBackgroundColor, className }) => (
  <div
    className={twMerge("relative top-[-2px] h-24 w-full lg:h-16", className)}
    style={{ backgroundColor: topBackgroundColor }}>
    <div
      className="absolute inset-0 bottom-[-2px] clip-path-diagonal-line"
      style={{ backgroundColor: bottomBackgroundColor }}
    />
  </div>
)

export default DiagonalDivider
