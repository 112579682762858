import { VideoCameraIcon } from "@heroicons/react/24/outline"
import { CheckBadgeIcon } from "@heroicons/react/24/solid"
import React from "react"

import ReviewStars from "../../components/shared/ReviewStars"

import EndorsementsSection from "./EndorsementsSection"
import { scrollToId } from "./ScrollToSection"

const VideoIcon = () => (
  <div className="flex items-center justify-center rounded-full bg-gray-light p-1">
    <VideoCameraIcon className="h-4 w-4 stroke-2 text-gray-dark" />
  </div>
)

const MobileHeaderSection = ({ practice }) => {
  const practitioner = practice.user
  const reviews = practice.reviews
  const roundedAverageRating = Math.round(practice.averageRating)
  const proCredential = practice.proCredentials[0]
  const proIsVirtualOnly = practice.locations.every((location) => location.kind === "virtual")

  const scrollToCredentials = (e) => scrollToId(e, "credentials")
  const scrollToReviews = (e) => scrollToId(e, "reviews")

  return (
    <section className="hidden flex-col gap-2 md:flex">
      <div className="-ml-6 w-[calc(100%+3rem)]">
        <img src={practitioner.profilePhotoUrl} alt={practitioner.name} className="aspect-square w-full" />
      </div>
      <h1 className="mt-2 flex items-center gap-2">
        <div className="text-[18px] font-bold">{practitioner.name}</div>
        <img className="h-6 w-6 text-teal" src="/images/icons/verified.svg" alt="Verified" />
      </h1>
      <div>{practitioner.title}</div>
      <div className="mt-2 flex items-center gap-2">
        <ReviewStars rating={roundedAverageRating} />
        <button className="font-bold text-teal underline" onClick={scrollToReviews}>
          {reviews.length} reviews
        </button>
      </div>
      <div className="flex items-center gap-2">
        {practice.city}, {practice.stateAbbreviation}
        {proIsVirtualOnly && <VideoIcon />}
      </div>
      {proCredential && (
        <div className="flex items-center gap-2">
          <div className="flex w-fit items-center gap-1 rounded-lg border border-gray px-2 py-1">
            <CheckBadgeIcon className="h-5 w-5 text-gray-dark opacity-[0.64]" />
            <div className="text-sm">{proCredential.credential.name}</div>
          </div>
          {practice.proCredentials.length > 1 && (
            <button className="text-sm" onClick={scrollToCredentials}>
              +{practice.proCredentials.length - 1} more
            </button>
          )}
        </div>
      )}
      <EndorsementsSection skills={practice.skills} showAllButton={false} count={2} className="mt-2" />
    </section>
  )
}

export default MobileHeaderSection
