import React from "react"
import { twMerge } from "tailwind-merge"

import { DEFAULT_HIGHLIGHT_CLASSES } from "../WebsiteBuilder/WebsiteBuilderPage"

import AnimatedSection from "./AnimatedSection"

const Quote = ({ id, theme, text, highlight, className, author, backgroundColor, color }) => {
  backgroundColor ||= theme.colorGreyLightest
  color ||= theme.colorTextDark

  return (
    <section
      id={id}
      className={twMerge("scroll-mt-24 py-24 md:px-5", className)}
      style={{ backgroundColor, fontFamily: theme.fontHeading, fontWeight: theme.fontHeadingWeight, color }}>
      <AnimatedSection
        className={twMerge(
          "container mx-auto text-center text-[28px] leading-10",
          highlight ? DEFAULT_HIGHLIGHT_CLASSES : ""
        )}>
        {text && `“${text}”`}
        {author && <div className="mt-4 text-base font-semibold">- {author}</div>}
      </AnimatedSection>
    </section>
  )
}

export default Quote
