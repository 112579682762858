import React from "react"
import { twMerge } from "tailwind-merge"

import AdditionalContent from "../ProProfileShared/AdditionalContent"
import AnimatedSection from "../ProProfileShared/AnimatedSection"
import { DEFAULT_HIGHLIGHT_CLASSES } from "../WebsiteBuilder/WebsiteBuilderPage"

const ValueStatementSection = ({ practice, theme, highlight }) => (
  <section
    id="value-statement"
    className="scroll-mt-24 py-24 md:px-5"
    style={{
      backgroundColor: theme.colorBgLight,
      color: theme.colorTextDark,
      fontFamily: theme.fontHeading,
      fontWeight: theme.fontHeadingWeight
    }}>
    <AnimatedSection className={twMerge("container mx-auto text-center", highlight ? DEFAULT_HIGHLIGHT_CLASSES : "")}>
      <AdditionalContent valueStatement={practice.valueStatement} />
    </AnimatedSection>
  </section>
)

export default ValueStatementSection
