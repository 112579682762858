import { PickerDropPane, PickerOverlay } from "filestack-react"
import React, { useState } from "react"
import { createPortal } from "react-dom"

export const FilePickerDropArea = ({ onUploadDone }) => {
  const pickerOptions = {
    exposeOriginalFile: true,
    maxFiles: 1,
    dropPane: {
      customText: "Drag ‘n’ drop or choose a file"
    }
  }

  return (
    <PickerDropPane apikey={process.env.FILESTACK_API_KEY} onUploadDone={onUploadDone} pickerOptions={pickerOptions} />
  )
}

export const FilePicker = ({
  as = "div",
  onOpen = () => {},
  onUploadDone,
  className,
  maxFiles = 1,
  disabled,
  children,
  transformations,
  fromSources
}) => {
  const [showFileUpload, setShowFileUpload] = useState(false)
  transformations ||= {
    crop: {
      aspectRatio: 1,
      force: true
    }
  }

  const pickerOptions = {
    onClose: () => setShowFileUpload(false),
    imageMin: [400, 400],
    transformations,
    exposeOriginalFile: true,
    maxFiles,
    fromSources,
    onOpen
  }

  const Tag = as

  return (
    <>
      <Tag role="button" className={className} onClick={disabled ? () => {} : () => setShowFileUpload(true)}>
        {children}
      </Tag>
      {showFileUpload && (
        <>
          {createPortal(
            <PickerOverlay
              apikey={process.env.FILESTACK_API_KEY}
              onUploadDone={onUploadDone}
              pickerOptions={pickerOptions}
            />,
            document.body
          )}
        </>
      )}
    </>
  )
}
