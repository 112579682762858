import { AcademicCapIcon, CheckBadgeIcon } from "@heroicons/react/24/outline"
import React from "react"

import HoverPopover from "../../components/shared/HoverPopover"
import Typography from "../../components/shared/Typography"
import { capitalize } from "../../utils/utils"
import { CredentialPopover, LicensePopover } from "../ProProfile/CredentialsSection"
import ScrollToSection from "../ProProfile/ScrollToSection"

const MyCredentialsSection = ({ practice }) => {
  const proCredentials = practice.proCredentials

  return (
    <ScrollToSection id="credentials" className="mb-0 border-none pb-0">
      <div className="mx-auto max-w-4xl py-12">
        <h2 className="mb-1 text-center font-profilePage text-2xl">MY CREDENTIALS</h2>
        <div className="mb-8 flex flex-row items-center justify-center gap-2">
          <img src="/images/healme_logo.svg" alt="Logo" className="h-6 w-6 rounded border border-gray-dark p-1" />
          <div className="text-sm text-gray-dark">Heal.me verified</div>
        </div>

        <div className="mt-4 grid grid-cols-2 gap-2 sm:grid-cols-1">
          {proCredentials.map((proCredential) => (
            <div key={`credential-${proCredential.credential.name}`} className="flex items-start gap-2">
              {proCredential.kind === "degree" ? (
                <AcademicCapIcon className="h-6 w-6 flex-none text-gray-dark" />
              ) : (
                <CheckBadgeIcon className="h-6 w-6 flex-none text-gray-dark" />
              )}
              <div>
                {proCredential.kind === "license" || proCredential.filestackDocumentName ? (
                  <HoverPopover
                    credentialKind={proCredential.kind}
                    popoverContent={
                      proCredential.kind === "license" ? (
                        <LicensePopover proCredential={proCredential} practitioner={practice.user} />
                      ) : (
                        <CredentialPopover proCredential={proCredential} />
                      )
                    }>
                    <span className="cursor-pointer border-b-2 border-dashed border-gray-light">
                      {proCredential.credential.name}
                    </span>
                  </HoverPopover>
                ) : (
                  <a
                    className="border-b-2 border-dashed border-gray-light"
                    target="_blank"
                    href={proCredential.filestackDocument}
                    rel="noreferrer">
                    {proCredential.credential.name}
                  </a>
                )}
                {proCredential.licenseNumber && proCredential.stateIssued && (
                  <Typography variant="smSubtitle">
                    State of {capitalize(proCredential.stateIssued)} #{proCredential.licenseNumber}
                  </Typography>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </ScrollToSection>
  )
}

export default MyCredentialsSection
