import React, { useState } from "react"
import { twMerge } from "tailwind-merge"

import { Button } from "../../components/shared/Buttons"

import { scrollToId } from "./ScrollToSection"

const EndorsementsSection = ({ skills, showAllButton, count, className, listClasses, buttonClasses }) => {
  const [showAllEndorsements, setShowAllEndorsements] = useState(false)
  const numSkills = skills.length
  const displayedSkills = showAllEndorsements ? skills : skills.slice(0, count)

  const scrollToReviews = (e) => scrollToId(e, "reviews")

  return (
    <section className={twMerge("mt-4 flex flex-col gap-4", className)}>
      <div className={twMerge("flex flex-wrap gap-2", listClasses)}>
        {displayedSkills.map((skill) => (
          <div key={skill.id} className="flex items-stretch justify-center text-sm">
            <div
              className={`rounded-l-lg border border-gray-light bg-gray-light px-3 py-1 ${
                skill.endorsementsCount === 0 ? "rounded-lg" : ""
              }`}>
              {skill.specialty.name}
            </div>
            {skill.endorsementsCount > 0 && (
              <div className="flex items-center rounded-r-lg border border-gray-light px-3 py-1">
                {skill.endorsementsCount}
              </div>
            )}
          </div>
        ))}
        {!showAllButton && numSkills > 4 && (
          <button className="text-sm" onClick={scrollToReviews}>
            +{numSkills - 1} more
          </button>
        )}
      </div>
      {showAllButton && numSkills > count && (
        <Button
          type="tertiary"
          className={twMerge("w-fit sm:w-full", buttonClasses)}
          onClick={(e) => {
            setShowAllEndorsements(!showAllEndorsements)
            if (showAllEndorsements) {
              scrollToReviews(e)
            }
          }}>
          {showAllEndorsements ? "See less" : "See all endorsements"}
        </Button>
      )}
    </section>
  )
}

export default EndorsementsSection
