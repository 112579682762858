import { StarIcon } from "@heroicons/react/24/solid"
import React from "react"

const ReviewStars = ({ rating, starColor }) => {
  rating = Math.round(rating)

  return (
    <div className="flex gap-0.5">
      {[...Array(rating)].map((item, index) => (
        <StarIcon key={index} className="h-5 w-5" style={{ color: starColor }} />
      ))}
      {rating < 5 && (
        <>
          {[...Array(5 - rating)].map((item, index) => (
            <StarIcon key={index} className="h-5 w-5 text-gray" />
          ))}
        </>
      )}
    </div>
  )
}

export default ReviewStars
