import {
  BuildingOfficeIcon,
  ChatBubbleLeftEllipsisIcon,
  CheckIcon,
  HomeIcon,
  VideoCameraIcon
} from "@heroicons/react/24/outline"
import React from "react"

import Typography from "../../components/shared/Typography"
import { joinWithAnd } from "../../utils/utils"
import AskQuestionButton from "../ProProfile/AskQuestionButton"
import ScrollToSection from "../ProProfile/ScrollToSection"

const MyPracticeSection = ({ practice }) => {
  const languages = joinWithAnd(practice.languages.map((l) => l.name))
  const ageWorkWiths = joinWithAnd(practice.ageWorkWiths.map((a) => a.name.toLowerCase()))
  const workWiths = joinWithAnd(practice.workWiths.map((w) => w.name.toLowerCase()))
  const officeLocation = practice.locations.find((l) => l.kind === "office")
  const virtualLocation = practice.locations.find((l) => l.kind === "virtual")
  const homeLocation = practice.locations.find((l) => l.kind === "home")

  return (
    <ScrollToSection id="about" className="mb-0 border-none pb-0">
      <div className="mx-auto max-w-4xl py-12">
        <h2 className="mb-8 text-center font-profilePage text-2xl">MY PRACTICE</h2>

        <div className="mt-4 grid grid-cols-2 gap-2 sm:grid-cols-1">
          {officeLocation && (
            <div className="flex items-start gap-2">
              <BuildingOfficeIcon className="mt-0.5 h-5 w-5 flex-none text-gray-dark" />
              <div>Offers in-person sessions</div>
            </div>
          )}
          {homeLocation && (
            <div className="flex items-start gap-2">
              <HomeIcon className="mt-0.5 h-5 w-5 flex-none text-gray-dark" />
              <div>Offers in-your-home sessions</div>
            </div>
          )}
          {virtualLocation && (
            <div className="flex items-start gap-2">
              <VideoCameraIcon className="mt-0.5 h-5 w-5 flex-none text-gray-dark" />
              <div>Offers virtual sessions</div>
            </div>
          )}
          {languages && (
            <div className="flex items-start gap-2">
              <ChatBubbleLeftEllipsisIcon className="mt-0.5 h-5 w-5 flex-none text-gray-dark" />
              <div>Speaks {languages}</div>
            </div>
          )}
          {ageWorkWiths && (
            <div className="flex items-start gap-2">
              <CheckIcon className="mt-0.5 h-5 w-5 flex-none text-gray-dark" />
              <div>Works with {ageWorkWiths}</div>
            </div>
          )}
          {workWiths && (
            <div className="flex items-start gap-2">
              <CheckIcon className="mt-0.5 h-5 w-5 flex-none text-gray-dark" />
              <div>Works with {workWiths}</div>
            </div>
          )}
        </div>
        <div className="mt-8 flex flex-col items-center">
          <AskQuestionButton practice={practice} className="rounded-lg border-pink text-pink" />
          <Typography variant="smSubtitle" as="div" className="mt-2">
            I respond in ~24 hours
          </Typography>
        </div>
      </div>
    </ScrollToSection>
  )
}

export default MyPracticeSection
