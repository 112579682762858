import React, { createContext, useContext, useState } from "react"

import { urqlClient } from "../utils/utils"

const CURRENT_USER_QUERY = `
  query CurrentUserContext {
    currentUser { id name firstName lastName phone email }
  }
`

export const CurrentUserContext = createContext()

export const CurrentUserProvider = ({ children, defaultUser }) => {
  const [user, setUser] = useState(defaultUser)

  const fetchUser = () => {
    urqlClient
      .query(CURRENT_USER_QUERY)
      .toPromise()
      .then((res) => {
        if (res.data?.currentUser) {
          setUser(res.data.currentUser)
        } else {
          console.error("Error fetching current user", res)
        }
      })
  }

  return <CurrentUserContext.Provider value={{ user, fetchUser, setUser }}>{children}</CurrentUserContext.Provider>
}

export const useCurrentUser = () => useContext(CurrentUserContext)
