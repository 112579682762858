import React, { useEffect } from "react"
import { twMerge } from "tailwind-merge"

const Toast = ({ children, position = "bottomLeft", type = "success", visible, setVisible, timeout = 6000 }) => {
  let timeoutId

  useEffect(() => {
    if (visible) {
      if (timeoutId) clearTimeout(timeoutId)
      timeoutId = setTimeout(() => {
        setVisible(false)
      }, timeout)
    }
  }, [visible])

  return (
    <div
      className={twMerge(
        "fixed z-[999999] max-w-[460px] rounded border px-6 py-4 duration-500 sm:w-[calc(100%-4rem)] [&>a]:cursor-pointer [&>a]:font-bold [&>a]:underline",
        type === "error"
          ? "border-red bg-red-light"
          : type === "warning"
          ? "border-orange bg-orange-light"
          : type === "info"
          ? "border-gray bg-gray-light"
          : "border-green bg-green-light",
        position === "bottomLeft"
          ? "bottom-[32px] left-[-460px]"
          : position === "topLeft"
          ? "left-[-460px] top-[32px]"
          : "",
        visible && "translate-x-[492px]"
      )}>
      {children}
    </div>
  )
}

export default Toast
