import React, { useCallback, useRef } from "react"

import { EditableValue, Label, TextArea } from "../../components/shared/Inputs"
import { stripHTMLTags } from "../../utils/utils"

import { useWebsiteBuilder } from "./WebsiteBuilderContext"

const ContentInput = ({
  name,
  fieldName,
  text,
  scrollToId,
  maxLength,
  InputTag = TextArea,
  textAreaRows = 1,
  inputLabel,
  inputValue,
  onChange,
  children
}) => {
  const { practice, setPractice, openField, setOpenField, lastSavedPractice, fetching, onSave, setHighlightedField } =
    useWebsiteBuilder()
  fieldName ||= name.toLowerCase()
  const inputRef = useRef(null)

  const getNestedValue = (obj, path) => path.split(".").reduce((acc, part) => acc && acc[part], obj)

  const setNestedValue = (obj, path, value) => {
    const parts = path.split(".")
    const lastPart = parts.pop()
    const target = parts.reduce((acc, part) => {
      if (!acc[part]) acc[part] = {}
      return acc[part]
    }, obj)
    target[lastPart] = value
  }

  const deepClone = (obj) => JSON.parse(JSON.stringify(obj))

  const characterCount = stripHTMLTags(getNestedValue(practice, fieldName))?.length || 0
  const isMaxReached = maxLength && characterCount >= maxLength

  const handleInputChange = useCallback(
    (e) => {
      const newValue = e.target.value
      const truncatedValue = maxLength ? newValue.slice(0, maxLength) : newValue
      setPractice((currentPractice) => {
        const newPractice = deepClone(currentPractice)
        setNestedValue(newPractice, fieldName, truncatedValue)
        return newPractice
      })
    },
    [maxLength, fieldName, setPractice]
  )

  return (
    <EditableValue
      name={name}
      value={inputValue || getNestedValue(practice, fieldName) || ""}
      saveDisabled={
        getNestedValue(practice, fieldName) === getNestedValue(lastSavedPractice, fieldName) ||
        getNestedValue(practice, fieldName)?.length < 2
      }
      disabled={fetching || (openField && openField !== name)}
      onOpened={() => {
        setOpenField(name)
        setHighlightedField(fieldName)
        document.getElementById(scrollToId)?.scrollIntoView({ behavior: "smooth" })
        setTimeout(() => inputRef.current?.focus(), 500)
      }}
      onClosed={() => setHighlightedField(null)}
      onSave={onSave}
      onCancel={() => {
        setOpenField(null)
        setPractice(deepClone(lastSavedPractice))
      }}>
      {text && <p className="mb-4 text-sm text-gray-dark">{text}</p>}
      {inputLabel && <Label htmlFor={`${name}-input`}>{inputLabel}</Label>}
      <div className="flex flex-col items-end gap-1">
        <InputTag
          ref={inputRef}
          id={`${name}-input`}
          name={name}
          value={inputValue || getNestedValue(practice, fieldName) || ""}
          onChange={onChange || handleInputChange}
          rows={textAreaRows}
        />
        {maxLength && (
          <div className={`text-xs ${isMaxReached ? "text-red" : "text-gray-dark"}`}>
            {characterCount}/{maxLength}
          </div>
        )}
      </div>
      {children}
    </EditableValue>
  )
}

export default ContentInput
