import React, { useState } from "react"

import { Button } from "../../components/shared/Buttons"
import Typography from "../../components/shared/Typography"

import EndorsementsSection from "./EndorsementsSection"
import ReviewCard from "./ReviewCard"
import ScrollToSection, { scrollToId } from "./ScrollToSection"

const ReviewsSection = ({ reviews, skills, practitionerName }) => {
  const [showAllReviews, setShowAllReviews] = useState(false)
  const displayedReviews = showAllReviews ? reviews : reviews.slice(0, 10)

  return (
    <ScrollToSection id="reviews">
      <Typography variant="h4" as="h2">
        {reviews.length} verified {reviews.length === 1 ? "review" : "reviews"}
      </Typography>
      <Typography variant="subtitle">
        Practitioners can&apos;t pay to alter or remove, but they can reply to reviews.
      </Typography>
      <EndorsementsSection skills={skills} showAllButton={true} count={10} />
      <div className="mt-6 flex flex-col gap-4">
        {displayedReviews.map((review) => (
          <ReviewCard key={`review-${review.id}`} review={review} practitionerName={practitionerName} />
        ))}
        {reviews.length > 10 && (
          <Button
            type="tertiary"
            className="w-fit sm:w-full"
            onClick={(e) => {
              setShowAllReviews(!showAllReviews)
              if (showAllReviews) {
                scrollToId(e, "reviews")
              }
            }}>
            {showAllReviews ? "See less" : "See all reviews"}
          </Button>
        )}
      </div>
    </ScrollToSection>
  )
}

export default ReviewsSection
