import { BuildingOfficeIcon, VideoCameraIcon } from "@heroicons/react/24/outline"
import { GoogleMap, LoadScript, Marker, Circle } from "@react-google-maps/api"
import React from "react"
import { twMerge } from "tailwind-merge"

import Typography from "../../components/shared/Typography"

import ScrollToSection from "./ScrollToSection"

const travelDistanceInMeters = (distanceInMiles) => Number(distanceInMiles) * 1609.34

// There are approximately 69 miles in one latitude degree. The 0.9 comes from estimating the half the size of the
// icon we're using to hide address at map zoom level 11. This will not work properly if you change the map zoom level!
const HIDE_LOCATION_ICON_ADJUSTMENT = 0.9 / 69

const LocationSection = ({ practice, className, showVirtualLocation = true }) => {
  const physicalLocations = practice.locations
    .filter((l) => (l.kind === "office" || l.kind === "home") && l.latitude !== null && l.longitude !== null)
    .sort((a, b) => b.kind.localeCompare(a.kind))

  const virtualLocation = practice.locations.filter((l) => l.kind === "virtual")[0]

  return (
    <ScrollToSection id="location" className="mt-[40px]">
      <div className={twMerge("flex flex-wrap justify-between gap-y-6 sm:flex-col", className)}>
        {physicalLocations.map((location) => {
          const markerPosition = { lat: location.latitude, lng: location.longitude }
          const mapCenter = { lat: location.latitude, lng: location.longitude }

          if (location.hideAddress) {
            // northern hemisphere
            if (location.latitude > 0) {
              mapCenter.lat = mapCenter.lat + HIDE_LOCATION_ICON_ADJUSTMENT
              markerPosition.lat = markerPosition.lat - HIDE_LOCATION_ICON_ADJUSTMENT
            } else {
              mapCenter.lat = mapCenter.lat + HIDE_LOCATION_ICON_ADJUSTMENT
              markerPosition.lat = markerPosition.lat - HIDE_LOCATION_ICON_ADJUSTMENT
            }
          }

          return (
            <div key={`location-${location.id}`}>
              <div className="h-[200px] w-[356px] sm:w-full">
                <LoadScript googleMapsApiKey="AIzaSyBJzO3o3qx-4b53mVm0BA23J1z_Aj7MWmE">
                  <GoogleMap
                    mapContainerStyle={{ width: "100%", height: "100%" }}
                    center={mapCenter}
                    zoom={location.kind === "home" ? 10 : !location.hideAddress ? 15 : 11}
                    options={{ disableDefaultUI: true }}>
                    {location.kind === "home" ? (
                      <Circle
                        center={markerPosition}
                        radius={travelDistanceInMeters(5)}
                        options={{ strokeColor: "#0D9DA4" }}
                      />
                    ) : location.hideAddress ? (
                      <Marker position={markerPosition} icon="/defaults/m5.png" />
                    ) : (
                      <Marker position={markerPosition} icon="/defaults/default-map-pin.svg" />
                    )}
                  </GoogleMap>
                </LoadScript>
              </div>
              <div className="mt-4 flex items-start gap-2">
                {location.kind === "office" ? (
                  <>
                    <div className="flex flex-none items-center justify-center rounded-full bg-gray-light p-1">
                      <BuildingOfficeIcon className="h-6 w-6 text-gray-dark" />
                    </div>
                    <div className="flex flex-col">
                      {!location.hideAddress && <div>{location.address}</div>}
                      <div>
                        {location.city}, {location.stateAbbreviation} {location.zip}
                      </div>
                      {location.hideAddress && (
                        <Typography variant="smSubtitle" as="div">
                          Address shown after booking
                        </Typography>
                      )}
                    </div>
                  </>
                ) : (
                  <>
                    <div className="flex flex-none items-center justify-center rounded-full bg-gray-light p-0.5">
                      <img src="/images/icons/car.svg" alt="Car" className="h-7 w-7 text-gray-dark" />
                    </div>
                    <div className="flex flex-col">
                      <div>
                        {location.city}, {location.stateAbbreviation} {location.zip}
                      </div>
                      <Typography variant="smSubtitle" as="div">
                        I come to you
                      </Typography>
                    </div>
                  </>
                )}
              </div>
            </div>
          )
        })}
      </div>
      {showVirtualLocation && virtualLocation && (
        <div className="mt-4 flex items-center gap-2">
          <div className="flex flex-none items-center justify-center rounded-full bg-gray-light p-1">
            <VideoCameraIcon className="h-6 w-6 text-gray-dark" />
          </div>
          <div>I offer video sessions</div>
        </div>
      )}
    </ScrollToSection>
  )
}

export default LocationSection
