import React from "react"
import { twMerge } from "tailwind-merge"

const CurvyDivider = ({ topBackgroundColor, bottomBackgroundColor, className }) => (
  <div style={{ backgroundColor: topBackgroundColor }} className={twMerge("h-10 w-full md:h-6", className)}>
    <svg
      viewBox="0 0 1425 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="h-full w-full"
      preserveAspectRatio="none">
      <path
        d="M709.986 34C1138.89 34 1349.58 0 1425 0V40H0V0C117.661 0 281.078 34 709.986 34Z"
        fill={bottomBackgroundColor}
      />
      <path
        d="M709.986 38C1138.89 38 1349.58 4 1425 4V40H0V4C117.661 4 281.078 38 709.986 38Z"
        fill={bottomBackgroundColor}
      />
    </svg>
  </div>
)

export default CurvyDivider
