import { ArrowUpTrayIcon } from "@heroicons/react/24/outline"
import { PickerOverlay } from "filestack-react"
import React, { useState } from "react"
import { createPortal } from "react-dom"
import { twMerge } from "tailwind-merge"

import { Button } from "../../components/shared/Buttons"
import { FilePicker } from "../../components/shared/FileStackWidgets"
import { BASE_INPUT_CLASSNAMES } from "../../components/shared/Inputs"
import { TabNavLink } from "../../components/shared/Links"
import { AnimatedModal } from "../../components/shared/Modal"
import Typography from "../../components/shared/Typography"

import { generateCustomSource, switchToCustomSourceOnOpen } from "./customPickerUtils"

const tabs = [
  { id: "uploaded", label: "Uploaded images" },
  { id: "upload", label: "Upload new" },
  { id: "stock", label: "Curated photos" }
]

export default function ImageUploadButton({
  practice,
  handleUploadImage,
  fetching,
  showCurateTab = true,
  hideS3Images = false,
  aspectRatio = null
}) {
  const [isOpen, setIsOpen] = useState(false)
  const [selectedImage, setSelectedImage] = useState(null)
  const initialTab = practice.allUploadedImages.length === 0 ? "upload" : "uploaded"
  const [activeTab, setActiveTab] = useState(initialTab)
  const [cropModalOpen, setCropModalOpen] = useState(false)

  const handleImageSelect = (imageUrl) => {
    setSelectedImage(imageUrl === selectedImage ? null : imageUrl)
  }

  const handleSave = () => {
    setIsOpen(false)
    setSelectedImage(null)
    const handle = selectedImage.split("/").pop()
    const provider = selectedImage.includes("amazonaws.com") ? "amazon_s3" : "filestack"
    handleUploadImage({ handles: [handle], provider })
  }

  const filteredTabs = showCurateTab ? tabs : tabs.filter((tab) => tab.id !== "stock")
  const uploadedImages = practice.allUploadedImages.filter(
    (imageUrl) => !hideS3Images || !imageUrl.includes("amazonaws.com")
  )

  const renderTabContent = () => {
    switch (activeTab) {
      case "upload":
        return (
          <div className="p-2">
            <Typography variant="body" className="mb-4">
              Click below to upload a new image.
            </Typography>
            <FilePicker
              as="button"
              onUploadDone={(res) => {
                const handles = res.filesUploaded.map((file) => file.handle)
                handleUploadImage({ handles, provider: "filestack" })
                setIsOpen(false)
              }}
              disabled={fetching}
              transformations={aspectRatio ? { crop: { aspectRatio, force: true } } : { crop: { force: true } }}
              className={twMerge(BASE_INPUT_CLASSNAMES, "flex h-8 w-fit items-center gap-2 font-bold")}
              maxFiles={1}>
              <ArrowUpTrayIcon className="h-4 w-4" />
              Upload
            </FilePicker>
          </div>
        )
      case "uploaded":
        return (
          <div className="mt-4 h-[calc(100vh-300px)] overflow-y-auto">
            <div className="grid grid-cols-3 items-center gap-4 p-2">
              {uploadedImages &&
                uploadedImages.map((imageUrl, index) => {
                  const thumbUrl = imageUrl.replace("amazonaws.com", "amazonaws.com/thumbnails")
                  return (
                    <div
                      key={index}
                      className={`flex cursor-pointer transition-all duration-200 ${
                        selectedImage === imageUrl ? "h-fit rounded shadow-lg ring-4 ring-teal" : ""
                      }`}
                      onClick={() => handleImageSelect(imageUrl)}>
                      <img
                        src={thumbUrl}
                        alt={`Uploaded image ${index + 1}`}
                        className="w-full self-center rounded object-cover"
                      />
                    </div>
                  )
                })}
            </div>
            {/* Empty state */}
            {practice.allUploadedImages.length === 0 && (
              <div className="flex h-full items-center justify-center">
                <Typography variant="body" className="text-gray-dark">
                  No images uploaded yet.
                </Typography>
              </div>
            )}
          </div>
        )
      case "stock":
        return (
          <div className="flex h-[calc(100vh-300px)] flex-col">
            <div className="grow overflow-y-auto">
              <div className="grid grid-cols-3 gap-4 p-2 pb-4">
                {practice.defaultHeroImages.map((imageUrl, index) => {
                  const thumbUrl = imageUrl.replace("amazonaws.com", "amazonaws.com/thumbnails")
                  return (
                    <div
                      key={index}
                      className={`cursor-pointer transition-all duration-200 ${
                        selectedImage === imageUrl ? "rounded shadow-lg ring-4 ring-teal" : ""
                      }`}
                      onClick={() => handleImageSelect(imageUrl)}>
                      <img
                        src={thumbUrl}
                        alt={`Default hero image ${index + 1}`}
                        className="w-full rounded object-cover"
                      />
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        )
      default:
        return null
    }
  }

  function CropModal({ imageUrl }) {
    const customSource = generateCustomSource([{ url: imageUrl, name: "Selected Image" }])
    return createPortal(
      <PickerOverlay
        apikey={process.env.FILESTACK_API_KEY}
        onUploadDone={(res) => {
          const handles = res.filesUploaded.map((file) => file.handle)
          handleUploadImage({ handles, provider: "filestack" })
          setIsOpen(false)
        }}
        pickerOptions={{
          transformations: aspectRatio ? { crop: { aspectRatio, force: true } } : { crop: { force: true } },
          fromSources: ["local_file_system", customSource],
          onOpen: switchToCustomSourceOnOpen,
          onClose: () => setCropModalOpen(false)
        }}
      />,
      document.body
    )
  }

  return (
    <>
      <Button type="tertiary" onClick={() => setIsOpen(true)} className="flex items-center gap-2" disabled={fetching}>
        <ArrowUpTrayIcon className="h-4 w-4" />
        {initialTab === "uploaded" ? "Change" : "Upload"}
      </Button>

      {/* Image upload modal */}
      <AnimatedModal
        visible={isOpen}
        hideModal={() => setIsOpen(false)}
        showFooter={true}
        actionButtonCopy={activeTab === "uploaded" ? "Use as is" : "Save"}
        footerButtons={[
          {
            label: "Crop",
            type: "primary",
            onClick: () => setCropModalOpen(true),
            show: activeTab === "uploaded",
            disabled: !selectedImage
          }
        ]}
        saveDisabled={selectedImage === null || fetching}
        onSave={handleSave}
        dialogClassName="max-h-[100vh] flex flex-col w-[720px] md:w-full">
        <div className="flex h-full flex-col">
          <Typography variant="h5" className="mb-4">
            Image Selection
          </Typography>

          <div className="mb-4 flex gap-8 border-b border-gray-light">
            {filteredTabs.map((tab) => (
              <TabNavLink
                key={tab.id}
                href="#"
                className="cursor-pointer"
                active={tab.id === activeTab}
                onClick={(e) => {
                  e.preventDefault()
                  setActiveTab(tab.id)
                }}>
                {tab.label}
              </TabNavLink>
            ))}
          </div>

          <div className="grow overflow-hidden">{renderTabContent()}</div>
        </div>
        {/* Crop modal */}
        {cropModalOpen && <CropModal imageUrl={selectedImage} />}
      </AnimatedModal>
    </>
  )
}
