import React, { useState } from "react"
import { twMerge } from "tailwind-merge"
import { useMutation } from "urql"

import { Button } from "../../components/shared/Buttons"
import Typography from "../../components/shared/Typography"
import { useToast } from "../../contexts/ToastContext"

import ImageUploadButton from "./ImageUploadButton"
import Thumbnail from "./Thumbnail"
import { useWebsiteBuilder } from "./WebsiteBuilderContext"

const UPDATE_USER_PHOTO_MUTATION = `
  mutation UpdateUserPhoto($handle: String!) {
    updateUserPhoto(handle: $handle) {
      result
      errors
      photoUrl
    }
  }
`

const HeadshotUpload = () => {
  const [editing, setEditing] = useState(false)
  const {
    practice,
    setPractice,
    openField,
    setOpenField,
    lastSavedPractice,
    onSave,
    setHighlightedField,
    aspectRatios
  } = useWebsiteBuilder()
  const [{ fetching }, updateUserPhoto] = useMutation(UPDATE_USER_PHOTO_MUTATION)

  const name = "Headshot"
  const photoUrl = practice.user.profilePhotoUrl
  const disabled = openField && openField !== name

  const aspectRatio = aspectRatios?.[practice.theme.name]?.["headshot"] ?? null

  const onCancel = () => {
    setOpenField(null)
    setPractice(lastSavedPractice)
    setHighlightedField(null)
  }

  const { showToast } = useToast()

  const handleUploadImage = ({ handles }) => {
    updateUserPhoto({
      handle: handles[0]
    }).then((result) => {
      setOpenField(null)
      if (result?.data?.updateUserPhoto?.result === "success") {
        setPractice((prev) => ({
          ...prev,
          user: {
            ...prev.user,
            profilePhotoUrl: result.data.updateUserPhoto.photoUrl
          }
        }))
        showToast("Your profile photo has been saved.")
      } else {
        console.error(result) // eslint-disable-line no-console
        let errorMessage = "There was an error saving your profile photo. Please try again later or contact support."
        if (result.data?.updateUserPhoto?.errors) errorMessage += ` ${result.data.updateUserPhoto.errors}`
        showToast({
          type: "error",
          content: errorMessage
        })
      }
    })
  }

  return (
    <div className={twMerge("bg-white", disabled ? "opacity-50" : "")}>
      {editing ? (
        <div>
          <div className="mb-2">
            <div className="mb-1 font-bold">{name}</div>
          </div>
          <p className="mb-4 text-sm text-gray-dark">
            Upload a professional headshot to introduce yourself and make a personal connection.
          </p>
          <Thumbnail photoUrl={photoUrl} className="h-20 w-20" />
          <div className="mt-4 flex justify-between">
            <ImageUploadButton
              practice={practice}
              handleUploadImage={handleUploadImage}
              fetching={fetching}
              showCurateTab={false}
              hideS3Images={true}
              aspectRatio={aspectRatio}
            />
            <div className="flex justify-end gap-4">
              <Button
                type="tertiary"
                size="small"
                onClick={() => {
                  setEditing(false)
                  onCancel()
                }}>
                Cancel
              </Button>
              <Button
                disabled={fetching}
                size="small"
                onClick={() => {
                  setEditing(false)
                  onSave()
                  setHighlightedField(null)
                }}>
                Save
              </Button>
            </div>
          </div>
        </div>
      ) : (
        <div className="flex">
          <div className="mr-4 flex-1">
            <Typography variant="title">{name}</Typography>
            <Thumbnail photoUrl={photoUrl} />
          </div>
          <div className="flex-none">
            <button
              className={twMerge("font-bold text-teal underline", disabled ? "pointer-events-none" : "")}
              onClick={() => {
                if (disabled) return
                setEditing(true)
                setOpenField(name)
                setHighlightedField(name)
                document.getElementById("about")?.scrollIntoView({ behavior: "smooth" })
              }}>
              Edit
            </button>
          </div>
        </div>
      )}
    </div>
  )
}

export default HeadshotUpload
