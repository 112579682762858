import React, { useEffect, useRef, useState } from "react"
import { twMerge } from "tailwind-merge"

import { DEFAULT_HIGHLIGHT_CLASSES } from "../WebsiteBuilder/WebsiteBuilderPage"

import AnimatedSection from "./AnimatedSection"
import SeeMore from "./SeeMore"

const GallerySection = ({
  practice,
  theme,
  highlight,
  style,
  imageClassName,
  containerClassName,
  maxHeight = window.innerWidth < 768 ? "336px" : "280px"
}) => {
  style ||= { backgroundColor: theme.colorGreyLightest }
  imageClassName ||= "aspect-square max-w-[275.5px] md:w-[160px] md:aspect-square"
  const [expanded, setExpanded] = useState(false)
  const imageContainerRef = useRef(null)

  useEffect(() => {
    if (imageContainerRef.current) {
      imageContainerRef.current.style.maxHeight = expanded ? `${imageContainerRef.current.scrollHeight}px` : maxHeight
    }
  }, [expanded, practice.galleryImages])

  return (
    <section style={style} id="gallery-photos" className="scroll-mt-24">
      <div className={twMerge("container mx-auto py-24 md:px-5 md:py-16", highlight ? DEFAULT_HIGHLIGHT_CLASSES : "")}>
        <AnimatedSection threshold={0.3} className={twMerge("flex flex-wrap justify-center gap-4", containerClassName)}>
          <div
            ref={imageContainerRef}
            style={{ maxHeight }}
            className={twMerge(
              "flex flex-wrap justify-center gap-4 overflow-hidden transition-[max-height] duration-1000 ease-in-out",
              containerClassName
            )}>
            {practice.galleryImages.map((image) => (
              <div key={image.id} className={imageClassName}>
                <img src={image.defaultPhotoUrl} alt="Gallery image" className="h-full w-full object-cover" />
              </div>
            ))}
          </div>
        </AnimatedSection>
        {practice.galleryImages.length > 4 && (
          <SeeMore
            className="mt-8"
            buttonClassName="hover:text-[var(--hover-color)]"
            buttonStyle={{ color: theme.colorTextDark, "--hover-color": theme.colorAccent }}
            onClick={() => {
              if (expanded) document.getElementById("gallery-photos").scrollIntoView({ behavior: "smooth" })
              setExpanded(!expanded)
            }}
          />
        )}
      </div>
    </section>
  )
}

export default GallerySection
