import React from "react"

import { CurrentUserProvider, useCurrentUser } from "../../contexts/CurrentUserContext"
import { ToastProvider, useToast } from "../../contexts/ToastContext"
import GraphQLProvider from "../../providers/GraphQLProvider"
import AboutSection from "../ProProfileShared/AboutSection"
import BottomCTA from "../ProProfileShared/BottomCTA"
import ChatWidget from "../ProProfileShared/ChatWidget"
import EndorsementsSection from "../ProProfileShared/EndorsementsSection"
import Footer from "../ProProfileShared/Footer"
import GallerySection from "../ProProfileShared/GallerySection"
import HeroSection from "../ProProfileShared/HeroSection"
import Map from "../ProProfileShared/Map"
import Navigation from "../ProProfileShared/Navigation"
import Quote from "../ProProfileShared/Quote"
import ReviewsSectionVariationOne from "../ProProfileShared/ReviewsSectionVariationOne"
import ServicesSection from "../ProProfileShared/ServicesSection"
import TopReviewsSection from "../ProProfileShared/TopReviewsSection"

import CurvyDivider from "./CurvyDivider"
import CurvyDividerReversed from "./CurvyDividerReversed"
import CurvyDividerWave from "./CurvyDividerWave"
import CurvyDividerWaveReversed from "./CurvyDividerWaveReversed"
import ValueStatementSection from "./ValueStatementSection"

const ProProfileThemeFour = ({ practice, highlight, styles, viewingInBuilder }) => {
  const theme = practice.theme
  const currentUser = useCurrentUser()
  const toast = useToast()

  return (
    <CurrentUserProvider>
      <GraphQLProvider>
        <ToastProvider>
          <main style={styles} className="md:overflow-x-hidden">
            <HeroSection
              practice={practice}
              theme={theme}
              highlight={highlight}
              viewingInBuilder={viewingInBuilder}
              showStars={true}
              Navigation={() => <Navigation practice={practice} theme={theme} viewingInBuilder={viewingInBuilder} />}
              ClipPath={() => (
                <CurvyDivider
                  topBackgroundColor="#00000000"
                  bottomBackgroundColor={theme.colorBgLight}
                  className="absolute bottom-[-2px] w-full"
                />
              )}
            />
            <TopReviewsSection
              practice={practice}
              theme={theme}
              className="pb-0"
              backgroundColor={theme.colorBgLight}
            />
            <CurvyDivider topBackgroundColor={theme.colorBgLight} bottomBackgroundColor={theme.colorGreyLightest} />
            <ServicesSection
              practice={practice}
              theme={theme}
              backgroundColor={theme.colorGreyLightest}
              cardBackgroundColor={theme.colorBgLight}
              viewingInBuilder={viewingInBuilder}
              includeImage={true}
              titleClassName="mt-10 min_md:mt-32 text-left md:text-center"
              className="pt-12 md:pb-20 md:pt-0"
              imageClassName="absolute bottom-0 left-0 h-auto"
              mobileImageClassName="px-0 mb-0"
              animateTitle={true}
            />
            <CurvyDividerReversed
              topBackgroundColor={theme.colorGreyLightest}
              bottomBackgroundColor={theme.colorBgLight}
            />
            <ValueStatementSection practice={practice} theme={theme} highlight={highlight === "valueStatement"} />
            <CurvyDividerWave topBackgroundColor={theme.colorBgLight} bottomBackgroundColor={theme.colorPrimary} />
            <AboutSection
              practice={practice}
              theme={theme}
              highlight={highlight}
              backgroundColor={theme.colorPrimary}
              cardBackgroundColor={theme.colorBgLight}
              cardClassName="md:mx-5 md:w-[calc(100%-40px)]"
              credentialsBackgroundColor={theme.colorBgLight}
              viewingInBuilder={viewingInBuilder}
            />
            <CurvyDividerWaveReversed
              topBackgroundColor={theme.colorPrimary}
              bottomBackgroundColor={theme.colorBgLight}
            />
            <ReviewsSectionVariationOne
              practice={practice}
              theme={theme}
              className="pb-0"
              starsClassName="justify-start md:justify-center"
            />
            <EndorsementsSection practice={practice} theme={theme} className="pt-10" titleClassName="font-medium" />
            <CurvyDivider topBackgroundColor={theme.colorBgLight} bottomBackgroundColor={theme.colorGreyLightest} />
            <GallerySection practice={practice} theme={theme} highlight={highlight === "Gallery photos"} />
            <CurvyDivider topBackgroundColor={theme.colorGreyLightest} bottomBackgroundColor={theme.colorBgLight} />
            <Quote
              theme={theme}
              text={practice.quote}
              author={practice.quoteAuthor}
              id="quote"
              highlight={highlight === "quote"}
              backgroundColor={theme.colorBgLight}
            />
            <BottomCTA
              practice={practice}
              theme={theme}
              highlight={highlight}
              viewingInBuilder={viewingInBuilder}
              ClipPath={() => (
                <CurvyDividerReversed
                  topBackgroundColor={theme.colorBgLight}
                  bottomBackgroundColor="#00000000"
                  className="absolute top-[-1px]"
                />
              )}
              ClipPathTwo={() => (
                <CurvyDivider
                  topBackgroundColor="#00000000"
                  bottomBackgroundColor={theme.colorSecondary}
                  className="absolute bottom-[-2px]"
                />
              )}
            />
            <div className="h-10" style={{ backgroundColor: theme.colorSecondary }} />
            <Map
              practice={practice}
              theme={theme}
              ClipPath={() => (
                <CurvyDividerReversed
                  topBackgroundColor={theme.colorSecondary}
                  bottomBackgroundColor="#00000000"
                  className="absolute top-[-1px]"
                />
              )}
              ClipPathTwo={() => (
                <CurvyDivider
                  topBackgroundColor="#00000000"
                  bottomBackgroundColor={theme.colorBgLight}
                  className="absolute bottom-0"
                />
              )}
            />
            <Footer
              practice={practice}
              theme={theme}
              backgroundColor={theme.colorBgLight}
              viewingInBuilder={viewingInBuilder}
            />
            <ChatWidget practice={practice} currentUser={currentUser} toast={toast} />
          </main>
        </ToastProvider>
      </GraphQLProvider>
    </CurrentUserProvider>
  )
}

export default ProProfileThemeFour
