import React from "react"
import { twMerge } from "tailwind-merge"

const Thumbnail = ({ photoUrl, className }) => (
  <div className={twMerge("flex h-12 w-12 items-center justify-center rounded-lg border border-gray", className)}>
    {photoUrl ? (
      <img src={photoUrl} alt="Headshot" className="aspect-square w-[calc(100%-4px)] rounded-lg" />
    ) : (
      <div className="aspect-square w-[calc(100%-4px)] rounded-lg bg-gray-light" />
    )}
  </div>
)

export default Thumbnail
