import { BuildingOfficeIcon, ChatBubbleLeftEllipsisIcon, CheckIcon, VideoCameraIcon } from "@heroicons/react/24/outline"
import React from "react"

import Typography from "../../components/shared/Typography"
import { joinWithAnd } from "../../utils/utils"

import AskQuestionButton from "./AskQuestionButton"
import ScrollToSection from "./ScrollToSection"

const AboutSection = ({ practice }) => {
  const languages = joinWithAnd(practice.languages.map((l) => l.name))
  const ageWorkWiths = joinWithAnd(practice.ageWorkWiths.map((a) => a.name.toLowerCase()))
  const workWiths = joinWithAnd(practice.workWiths.map((w) => w.name.toLowerCase()))
  const officeLocation = practice.locations.find((l) => l.kind === "office")
  const virtualLocation = practice.locations.find((l) => l.kind === "virtual")

  return (
    <>
      <ScrollToSection id="about" className="border-none">
        <Typography variant="capitalHeading" as="h2">
          {practice.user.firstName}&apos;s practice
        </Typography>
        <div className="mt-4 grid grid-cols-2 gap-2 sm:grid-cols-1">
          {officeLocation && (
            <div className="flex items-start gap-2">
              <BuildingOfficeIcon className="mt-0.5 h-5 w-5 flex-none text-gray-dark" />
              <div>In-person sessions</div>
            </div>
          )}
          {virtualLocation && (
            <div className="flex items-start gap-2">
              <VideoCameraIcon className="mt-0.5 h-5 w-5 flex-none text-gray-dark" />
              <div>Virtual sessions</div>
            </div>
          )}
          {languages && (
            <div className="flex items-start gap-2">
              <ChatBubbleLeftEllipsisIcon className="mt-0.5 h-5 w-5 flex-none text-gray-dark" />
              <div>Speaks {languages}</div>
            </div>
          )}
          {ageWorkWiths && (
            <div className="flex items-start gap-2">
              <CheckIcon className="mt-0.5 h-5 w-5 flex-none text-gray-dark" />
              <div>Works with {ageWorkWiths}</div>
            </div>
          )}
          {workWiths && (
            <div className="flex items-start gap-2">
              <CheckIcon className="mt-0.5 h-5 w-5 flex-none text-gray-dark" />
              <div>Works with {workWiths}</div>
            </div>
          )}
        </div>
        <div className="mt-4">
          <AskQuestionButton practice={practice} />
          <Typography variant="smSubtitle" as="div" className="mt-2">
            Responds in ~24 hours
          </Typography>
        </div>
      </ScrollToSection>
    </>
  )
}

export default AboutSection
