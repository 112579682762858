import React from "react"
import { twMerge } from "tailwind-merge"

const CardTitle = ({ className, style, children }) => (
  <h3 className={twMerge("truncate text-[27px] font-bold leading-loose lg:text-[22px]", className)} style={style}>
    {children}
  </h3>
)

export default CardTitle
