import React from "react"

import Badge from "../../components/shared/Badge"
import { Box } from "../../components/shared/Layout"

import ReviewCardSignature from "./ReviewCardSignature"
import { scrollToId } from "./ScrollToSection"

const ReviewsHeaderSection = ({ reviews, practitionerName, seeAllCopy }) => {
  const filteredReviews = reviews.filter((r) => r.review)
  const review = filteredReviews.sort((a, b) => b.timesBooked - a.timesBooked)[0]

  if (!review) return null

  let reviewText = review.review.trim()
  if (reviewText.length > 240) {
    reviewText = reviewText.slice(0, 240) + "..."
  }
  const scrollToReviews = (e) => scrollToId(e, "reviews")

  return (
    <section className="md:-ml-6 md:mt-8 md:w-[calc(100%+3rem)]">
      <Box className="flex w-full flex-col gap-4 p-6 md:rounded-none md:border-none md:bg-gray-ultralight sm:rounded-none sm:border-none sm:p-6">
        <div>&ldquo;{reviewText}&rdquo;</div>
        <div className="flex items-center justify-between sm:flex-col sm:items-start sm:gap-4">
          <ReviewCardSignature review={review} reviewsCount={reviews.length} practitionerName={practitionerName}>
            {review.timesBooked > 1 && (
              <Badge
                type="info"
                className="w-fit border border-gray-light bg-white py-[3px] text-[12px] font-bold normal-case tracking-normal">
                Booked {review.timesBooked} times
              </Badge>
            )}
          </ReviewCardSignature>
          <div className="hidden flex-none md:block">
            {reviews.length > 1 && (
              <button className="font-bold text-teal underline" onClick={scrollToReviews}>
                {seeAllCopy}
              </button>
            )}
          </div>
        </div>
      </Box>
    </section>
  )
}

export default ReviewsHeaderSection
